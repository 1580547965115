const __CACHE__ = {
  '0':
  {
    neutral0: '#6b675f',
    neutral: '#494740',
    neutral100: '#12110e',
    tint: '#0058c9',
    success: '#006c49',
    error: '#ba1a24',
    info: '#8c00e6',
    warning: '#8e4e00'
  },
  '1':
  {
    neutral0: 'rgba(73,71,64,0.16)',
    neutral: 'rgba(73,71,64,0.4)',
    neutral100: 'rgba(73,71,64,0.72)',
    tint: 'rgba(0,88,201,0.4)',
    tint100: 'rgba(0,88,201,0.72)',
    success: 'rgba(0,108,73,0.4)',
    success100: 'rgba(0,108,73,0.72)',
    error: 'rgba(186,26,36,0.4)',
    error100: 'rgba(186,26,36,0.72)',
    info: 'rgba(140,0,230,0.4)',
    info100: 'rgba(140,0,230,0.72)',
    warning: 'rgba(142,78,0,0.4)',
    warning100: 'rgba(142,78,0,0.72)'
  },
  '2':
  {
    neutral0: '#ecf0ff',
    neutral: '#ecf0ff',
    neutral100: '#ffffff'
  },
  '3':
  {
    neutral0: 'rgba(236,240,255,0.16)',
    neutral: 'rgba(236,240,255,0.4)',
    neutral100: 'rgba(236,240,255,0.72)'
  },
  '4':
  {
    neutral0: '#0058c9',
    neutral: '#0040a0',
    neutral100: '#000b3a'
  },
  '5':
  {
    neutral0: 'rgba(0,64,160,0.16)',
    neutral: 'rgba(0,64,160,0.4)',
    neutral100: 'rgba(0,64,160,0.72)'
  },
  '6':
  {
    neutral0: '#003824',
    neutral: '#002114',
    neutral100: '#00150b'
  },
  '7':
  {
    neutral0: 'rgba(0,33,20,0.16)',
    neutral: 'rgba(0,33,20,0.4)',
    neutral100: 'rgba(0,33,20,0.72)'
  },
  '8':
  {
    neutral0: '#006c49',
    neutral: '#005236',
    neutral100: '#00150b'
  },
  '9':
  {
    neutral0: 'rgba(0,82,54,0.16)',
    neutral: 'rgba(0,82,54,0.4)',
    neutral100: 'rgba(0,82,54,0.72)'
  },
  '10':
  {
    neutral0: '#feeded',
    neutral: '#feeded',
    neutral100: '#ffffff'
  },
  '11':
  {
    neutral0: 'rgba(254,237,237,0.16)',
    neutral: 'rgba(254,237,237,0.4)',
    neutral100: 'rgba(254,237,237,0.72)'
  },
  '12':
  {
    neutral0: '#ba1a24',
    neutral: '#8e1119',
    neutral100: '#2b0203'
  },
  '13':
  {
    neutral0: 'rgba(142,17,25,0.16)',
    neutral: 'rgba(142,17,25,0.4)',
    neutral100: 'rgba(142,17,25,0.72)'
  },
  '14':
  {
    neutral0: '#f4eeff',
    neutral: '#f4eeff',
    neutral100: '#ffffff'
  },
  '15':
  {
    neutral0: 'rgba(244,238,255,0.16)',
    neutral: 'rgba(244,238,255,0.4)',
    neutral100: 'rgba(244,238,255,0.72)'
  },
  '16':
  {
    neutral0: '#8c00e6',
    neutral: '#6a00b1',
    neutral100: '#1e0037'
  },
  '17':
  {
    neutral0: 'rgba(106,0,177,0.16)',
    neutral: 'rgba(106,0,177,0.4)',
    neutral100: 'rgba(106,0,177,0.72)'
  },
  '18':
  {
    neutral0: '#532200',
    neutral: '#360f00',
    neutral100: '#260600'
  },
  '19':
  {
    neutral0: 'rgba(54,15,0,0.16)',
    neutral: 'rgba(54,15,0,0.4)',
    neutral100: 'rgba(54,15,0,0.72)'
  },
  '20':
  {
    neutral0: '#8e4e00',
    neutral: '#703700',
    neutral100: '#260600'
  },
  '21':
  {
    neutral0: 'rgba(112,55,0,0.16)',
    neutral: 'rgba(112,55,0,0.4)',
    neutral100: 'rgba(112,55,0,0.72)'
  },
  '22':
  {
    neutral0: '#9b978d',
    neutral: '#e5e2db',
    neutral100: '#ffffff',
    tint: '#6e9bff',
    success: '#00b47d',
    error: '#f87579',
    info: '#b984ff',
    warning: '#dd8c00'
  },
  '23':
  {
    neutral0: 'rgba(229,226,219,0.16)',
    neutral: 'rgba(229,226,219,0.4)',
    neutral100: 'rgba(229,226,219,0.72)',
    tint: 'rgba(110,155,255,0.4)',
    tint100: 'rgba(110,155,255,0.72)',
    success: 'rgba(0,180,125,0.4)',
    success100: 'rgba(0,180,125,0.72)',
    error: 'rgba(248,117,121,0.4)',
    error100: 'rgba(248,117,121,0.72)',
    info: 'rgba(185,132,255,0.4)',
    info100: 'rgba(185,132,255,0.72)',
    warning: 'rgba(221,140,0,0.4)',
    warning100: 'rgba(221,140,0,0.72)'
  },
  '24':
  {
    neutral0: '#6e9bff',
    neutral: '#b1c5ff',
    neutral100: '#ffffff'
  },
  '25':
  {
    neutral0: 'rgba(177,197,255,0.16)',
    neutral: 'rgba(177,197,255,0.4)',
    neutral100: 'rgba(177,197,255,0.72)'
  },
  '26':
  {
    neutral0: '#00b47d',
    neutral: '#68dba9',
    neutral100: '#ffffff'
  },
  '27':
  {
    neutral0: 'rgba(104,219,169,0.16)',
    neutral: 'rgba(104,219,169,0.4)',
    neutral100: 'rgba(104,219,169,0.72)'
  },
  '28':
  {
    neutral0: '#f87579',
    neutral: '#fab5b6',
    neutral100: '#ffffff'
  },
  '29':
  {
    neutral0: 'rgba(250,181,182,0.16)',
    neutral: 'rgba(250,181,182,0.4)',
    neutral100: 'rgba(250,181,182,0.72)'
  },
  '30':
  {
    neutral0: '#b984ff',
    neutral: '#d4bbff',
    neutral100: '#ffffff'
  },
  '31':
  {
    neutral0: 'rgba(212,187,255,0.16)',
    neutral: 'rgba(212,187,255,0.4)',
    neutral100: 'rgba(212,187,255,0.72)'
  },
  '32':
  {
    neutral0: '#dd8c00',
    neutral: '#ffb86b',
    neutral100: '#ffffff'
  },
  '33':
  {
    neutral0: 'rgba(255,184,107,0.16)',
    neutral: 'rgba(255,184,107,0.4)',
    neutral100: 'rgba(255,184,107,0.72)'
  },
  '34': { backgroundColor: '#ffffff' },
  '35': { color: '#6b675f' },
  '36': { color: '#494740' },
  '37': { color: '#12110e' },
  '38': { color: '#0058c9' },
  '39': { color: '#006c49' },
  '40': { color: '#ba1a24' },
  '41': { color: '#8c00e6' },
  '42': { color: '#8e4e00' },
  '43': { borderColor: 'rgba(73,71,64,0.16)' },
  '44': { backgroundColor: 'rgba(73,71,64,0.16)' },
  '45': { borderColor: 'rgba(73,71,64,0.4)' },
  '46': { backgroundColor: 'rgba(73,71,64,0.4)' },
  '47': { borderColor: 'rgba(73,71,64,0.72)' },
  '48': { backgroundColor: 'rgba(73,71,64,0.72)' },
  '49': { borderColor: 'rgba(0,88,201,0.4)' },
  '50': { backgroundColor: 'rgba(0,88,201,0.4)' },
  '51': { borderColor: 'rgba(0,88,201,0.72)' },
  '52': { backgroundColor: 'rgba(0,88,201,0.72)' },
  '53': { borderColor: 'rgba(0,108,73,0.4)' },
  '54': { backgroundColor: 'rgba(0,108,73,0.4)' },
  '55': { borderColor: 'rgba(0,108,73,0.72)' },
  '56': { backgroundColor: 'rgba(0,108,73,0.72)' },
  '57': { borderColor: 'rgba(186,26,36,0.4)' },
  '58': { backgroundColor: 'rgba(186,26,36,0.4)' },
  '59': { borderColor: 'rgba(186,26,36,0.72)' },
  '60': { backgroundColor: 'rgba(186,26,36,0.72)' },
  '61': { borderColor: 'rgba(140,0,230,0.4)' },
  '62': { backgroundColor: 'rgba(140,0,230,0.4)' },
  '63': { borderColor: 'rgba(140,0,230,0.72)' },
  '64': { backgroundColor: 'rgba(140,0,230,0.72)' },
  '65': { borderColor: 'rgba(142,78,0,0.4)' },
  '66': { backgroundColor: 'rgba(142,78,0,0.4)' },
  '67': { borderColor: 'rgba(142,78,0,0.72)' },
  '68': { backgroundColor: 'rgba(142,78,0,0.72)' },
  '69': { backgroundColor: '#f8f8f7' },
  '70': { backgroundColor: '#f2f1ee' },
  '71': { backgroundColor: '#ebe9e5' },
  '72': { backgroundColor: '#0065de' },
  '73': { color: '#ecf0ff' },
  '74': { color: '#ffffff' },
  '75': { borderColor: 'rgba(236,240,255,0.16)' },
  '76': { backgroundColor: 'rgba(236,240,255,0.16)' },
  '77': { borderColor: 'rgba(236,240,255,0.4)' },
  '78': { backgroundColor: 'rgba(236,240,255,0.4)' },
  '79': { borderColor: 'rgba(236,240,255,0.72)' },
  '80': { backgroundColor: 'rgba(236,240,255,0.72)' },
  '81': { backgroundColor: 'rgba(177,197,255,0.4)' },
  '82': { color: '#0040a0' },
  '83': { color: '#000b3a' },
  '84': { borderColor: 'rgba(0,64,160,0.16)' },
  '85': { backgroundColor: 'rgba(0,64,160,0.16)' },
  '86': { borderColor: 'rgba(0,64,160,0.4)' },
  '87': { backgroundColor: 'rgba(0,64,160,0.4)' },
  '88': { borderColor: 'rgba(0,64,160,0.72)' },
  '89': { backgroundColor: 'rgba(0,64,160,0.72)' },
  '90': { backgroundColor: '#00c388' },
  '91': { color: '#003824' },
  '92': { color: '#002114' },
  '93': { color: '#00150b' },
  '94': { borderColor: 'rgba(0,33,20,0.16)' },
  '95': { backgroundColor: 'rgba(0,33,20,0.16)' },
  '96': { borderColor: 'rgba(0,33,20,0.4)' },
  '97': { backgroundColor: 'rgba(0,33,20,0.4)' },
  '98': { borderColor: 'rgba(0,33,20,0.72)' },
  '99': { backgroundColor: 'rgba(0,33,20,0.72)' },
  '100': { backgroundColor: 'rgba(104,219,169,0.4)' },
  '101': { color: '#005236' },
  '102': { borderColor: 'rgba(0,82,54,0.16)' },
  '103': { backgroundColor: 'rgba(0,82,54,0.16)' },
  '104': { borderColor: 'rgba(0,82,54,0.4)' },
  '105': { backgroundColor: 'rgba(0,82,54,0.4)' },
  '106': { borderColor: 'rgba(0,82,54,0.72)' },
  '107': { backgroundColor: 'rgba(0,82,54,0.72)' },
  '108': { backgroundColor: '#d01e2a' },
  '109': { color: '#feeded' },
  '110': { borderColor: 'rgba(254,237,237,0.16)' },
  '111': { backgroundColor: 'rgba(254,237,237,0.16)' },
  '112': { borderColor: 'rgba(254,237,237,0.4)' },
  '113': { backgroundColor: 'rgba(254,237,237,0.4)' },
  '114': { borderColor: 'rgba(254,237,237,0.72)' },
  '115': { backgroundColor: 'rgba(254,237,237,0.72)' },
  '116': { backgroundColor: 'rgba(250,181,182,0.4)' },
  '117': { color: '#8e1119' },
  '118': { color: '#2b0203' },
  '119': { borderColor: 'rgba(142,17,25,0.16)' },
  '120': { backgroundColor: 'rgba(142,17,25,0.16)' },
  '121': { borderColor: 'rgba(142,17,25,0.4)' },
  '122': { backgroundColor: 'rgba(142,17,25,0.4)' },
  '123': { borderColor: 'rgba(142,17,25,0.72)' },
  '124': { backgroundColor: 'rgba(142,17,25,0.72)' },
  '125': { backgroundColor: '#9c0bff' },
  '126': { color: '#f4eeff' },
  '127': { borderColor: 'rgba(244,238,255,0.16)' },
  '128': { backgroundColor: 'rgba(244,238,255,0.16)' },
  '129': { borderColor: 'rgba(244,238,255,0.4)' },
  '130': { backgroundColor: 'rgba(244,238,255,0.4)' },
  '131': { borderColor: 'rgba(244,238,255,0.72)' },
  '132': { backgroundColor: 'rgba(244,238,255,0.72)' },
  '133': { backgroundColor: 'rgba(212,187,255,0.4)' },
  '134': { color: '#6a00b1' },
  '135': { color: '#1e0037' },
  '136': { borderColor: 'rgba(106,0,177,0.16)' },
  '137': { backgroundColor: 'rgba(106,0,177,0.16)' },
  '138': { borderColor: 'rgba(106,0,177,0.4)' },
  '139': { backgroundColor: 'rgba(106,0,177,0.4)' },
  '140': { borderColor: 'rgba(106,0,177,0.72)' },
  '141': { backgroundColor: 'rgba(106,0,177,0.72)' },
  '142': { backgroundColor: '#ed9900' },
  '143': { color: '#532200' },
  '144': { color: '#360f00' },
  '145': { color: '#260600' },
  '146': { borderColor: 'rgba(54,15,0,0.16)' },
  '147': { backgroundColor: 'rgba(54,15,0,0.16)' },
  '148': { borderColor: 'rgba(54,15,0,0.4)' },
  '149': { backgroundColor: 'rgba(54,15,0,0.4)' },
  '150': { borderColor: 'rgba(54,15,0,0.72)' },
  '151': { backgroundColor: 'rgba(54,15,0,0.72)' },
  '152': { backgroundColor: 'rgba(255,184,107,0.4)' },
  '153': { color: '#703700' },
  '154': { borderColor: 'rgba(112,55,0,0.16)' },
  '155': { backgroundColor: 'rgba(112,55,0,0.16)' },
  '156': { borderColor: 'rgba(112,55,0,0.4)' },
  '157': { backgroundColor: 'rgba(112,55,0,0.4)' },
  '158': { borderColor: 'rgba(112,55,0,0.72)' },
  '159': { backgroundColor: 'rgba(112,55,0,0.72)' },
  '160': { backgroundColor: '#32302b' },
  '161': { color: '#9b978d' },
  '162': { color: '#e5e2db' },
  '163': { color: '#6e9bff' },
  '164': { color: '#00b47d' },
  '165': { color: '#f87579' },
  '166': { color: '#b984ff' },
  '167': { color: '#dd8c00' },
  '168': { borderColor: 'rgba(229,226,219,0.16)' },
  '169': { backgroundColor: 'rgba(229,226,219,0.16)' },
  '170': { borderColor: 'rgba(229,226,219,0.4)' },
  '171': { backgroundColor: 'rgba(229,226,219,0.4)' },
  '172': { borderColor: 'rgba(229,226,219,0.72)' },
  '173': { backgroundColor: 'rgba(229,226,219,0.72)' },
  '174': { borderColor: 'rgba(110,155,255,0.4)' },
  '175': { backgroundColor: 'rgba(110,155,255,0.4)' },
  '176': { borderColor: 'rgba(110,155,255,0.72)' },
  '177': { backgroundColor: 'rgba(110,155,255,0.72)' },
  '178': { borderColor: 'rgba(0,180,125,0.4)' },
  '179': { backgroundColor: 'rgba(0,180,125,0.4)' },
  '180': { borderColor: 'rgba(0,180,125,0.72)' },
  '181': { backgroundColor: 'rgba(0,180,125,0.72)' },
  '182': { borderColor: 'rgba(248,117,121,0.4)' },
  '183': { backgroundColor: 'rgba(248,117,121,0.4)' },
  '184': { borderColor: 'rgba(248,117,121,0.72)' },
  '185': { backgroundColor: 'rgba(248,117,121,0.72)' },
  '186': { borderColor: 'rgba(185,132,255,0.4)' },
  '187': { backgroundColor: 'rgba(185,132,255,0.4)' },
  '188': { borderColor: 'rgba(185,132,255,0.72)' },
  '189': { backgroundColor: 'rgba(185,132,255,0.72)' },
  '190': { borderColor: 'rgba(221,140,0,0.4)' },
  '191': { backgroundColor: 'rgba(221,140,0,0.4)' },
  '192': { borderColor: 'rgba(221,140,0,0.72)' },
  '193': { backgroundColor: 'rgba(221,140,0,0.72)' },
  '194': { backgroundColor: '#272622' },
  '195': { backgroundColor: '#1d1b18' },
  '196': { backgroundColor: '#12110e' },
  '197': { backgroundColor: 'rgba(0,42,120,0.4)' },
  '198': { color: '#b1c5ff' },
  '199': { borderColor: 'rgba(177,197,255,0.16)' },
  '200': { backgroundColor: 'rgba(177,197,255,0.16)' },
  '201': { borderColor: 'rgba(177,197,255,0.4)' },
  '202': { borderColor: 'rgba(177,197,255,0.72)' },
  '203': { backgroundColor: 'rgba(177,197,255,0.72)' },
  '204': { backgroundColor: 'rgba(0,56,36,0.4)' },
  '205': { color: '#68dba9' },
  '206': { borderColor: 'rgba(104,219,169,0.16)' },
  '207': { backgroundColor: 'rgba(104,219,169,0.16)' },
  '208': { borderColor: 'rgba(104,219,169,0.4)' },
  '209': { borderColor: 'rgba(104,219,169,0.72)' },
  '210': { backgroundColor: 'rgba(104,219,169,0.72)' },
  '211': { backgroundColor: 'rgba(101,9,15,0.4)' },
  '212': { color: '#fab5b6' },
  '213': { borderColor: 'rgba(250,181,182,0.16)' },
  '214': { backgroundColor: 'rgba(250,181,182,0.16)' },
  '215': { borderColor: 'rgba(250,181,182,0.4)' },
  '216': { borderColor: 'rgba(250,181,182,0.72)' },
  '217': { backgroundColor: 'rgba(250,181,182,0.72)' },
  '218': { backgroundColor: 'rgba(74,0,126,0.4)' },
  '219': { color: '#d4bbff' },
  '220': { borderColor: 'rgba(212,187,255,0.16)' },
  '221': { backgroundColor: 'rgba(212,187,255,0.16)' },
  '222': { borderColor: 'rgba(212,187,255,0.4)' },
  '223': { borderColor: 'rgba(212,187,255,0.72)' },
  '224': { backgroundColor: 'rgba(212,187,255,0.72)' },
  '225': { backgroundColor: 'rgba(83,34,0,0.4)' },
  '226': { color: '#ffb86b' },
  '227': { borderColor: 'rgba(255,184,107,0.16)' },
  '228': { backgroundColor: 'rgba(255,184,107,0.16)' },
  '229': { borderColor: 'rgba(255,184,107,0.4)' },
  '230': { borderColor: 'rgba(255,184,107,0.72)' },
  '231': { backgroundColor: 'rgba(255,184,107,0.72)' },
  '232':
  {
    fontFamily: 'System',
    fontSize: 11,
    fontWeight: '400',
    lineHeight: 16
  },
  '233':
  {
    fontFamily: 'System',
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 16
  },
  '234':
  {
    fontFamily: 'System',
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 20
  },
  '235':
  {
    fontFamily: 'System',
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 20
  },
  '236':
  {
    fontFamily: 'System',
    fontSize: 18,
    fontWeight: '400',
    lineHeight: 24
  },
  '237':
  {
    fontFamily: 'System',
    fontSize: 24,
    fontWeight: '700',
    lineHeight: 28
  },
  '238':
  {
    fontFamily: 'System',
    fontSize: 20,
    fontWeight: '700',
    lineHeight: 24
  },
  '239':
  {
    fontFamily: 'System',
    fontSize: 18,
    fontWeight: '700',
    lineHeight: 20
  },
  '240':
  {
    fontFamily: 'System',
    fontSize: 16,
    fontWeight: '700',
    lineHeight: 20
  },
  '241':
  {
    fontFamily: 'System',
    fontSize: 14,
    fontWeight: '700',
    lineHeight: 16
  },
  '242':
  {
    fontFamily: 'System',
    fontSize: 12,
    fontWeight: '700',
    lineHeight: 16
  },
  '243':
  {
    fontFamily: 'System',
    fontSize: 18,
    fontWeight: '400',
    lineHeight: 28
  },
  '244':
  {
    fontFamily: 'System',
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 24
  },
  '245':
  {
    fontFamily: 'System',
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 24
  },
  '246':
  {
    fontFamily: 'System',
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 20
  },
  '247':
  {
    fontFamily: 'System',
    fontSize: 11,
    fontWeight: '400',
    lineHeight: 20
  },
  '248':
  {
    fontFamily: 'System',
    fontSize: 14,
    fontWeight: '700',
    lineHeight: 16,
    textTransform: 'uppercase',
    letterSpacing: 1.05
  },
  '249':
  {
    fontFamily: 'System',
    fontSize: 12,
    fontWeight: '700',
    lineHeight: 16,
    textTransform: 'uppercase',
    letterSpacing: 0.8999999999999999
  },
  '250':
  {
    fontFamily: 'System',
    fontSize: 10,
    fontWeight: '700',
    lineHeight: 12,
    textTransform: 'uppercase',
    letterSpacing: 0.75
  },
  '251':
  {
    fontFamily:
      'SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace',
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 20
  },
  '252':
  {
    fontFamily: 'System',
    fontSize: 20,
    fontWeight: '400',
    lineHeight: 28
  },
  '253':
  {
    fontFamily: 'System',
    fontSize: 32,
    fontWeight: '700',
    lineHeight: 36
  },
  '254':
  {
    fontFamily: 'System',
    fontSize: 20,
    fontWeight: '400',
    lineHeight: 32
  },
  '255':
  {
    fontFamily: 'System',
    fontSize: 16,
    fontWeight: '700',
    lineHeight: 20,
    textTransform: 'uppercase',
    letterSpacing: 1.2
  },
  '256':
  {
    fontFamily:
      'SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace',
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 24
  },
  '257':
  {
    fontFamily: 'System',
    fontSize: 24,
    fontWeight: '400',
    lineHeight: 32
  },
  '258':
  {
    fontFamily: 'System',
    fontSize: 36,
    fontWeight: '700',
    lineHeight: 40
  },
  '259':
  {
    fontFamily: 'System',
    fontSize: 24,
    fontWeight: '400',
    lineHeight: 36
  },
  '260':
  {
    fontFamily: 'System',
    fontSize: 28,
    fontWeight: '700',
    lineHeight: 32
  },
  '261':
  {
    fontFamily: 'System',
    fontSize: 42,
    fontWeight: '700',
    lineHeight: 48
  },
  '262': { fontWeight: '400' },
  '263': { fontWeight: '700' },
  '264': { hands: 'doc-l' },
  '265': { defaultColor: 'neutral' },
  '266': { defaultColor: 'neutral100' },
  '267': { '50': '50', '60': '60' },
  '268': { '50': 1, '60': 2.25 },
  '269':
  {
    defaultBackground: 'neutral',
    defaultActiveForeground: 'tint',
    defaultInactiveForeground: 'neutral0',
    defaultBorderColor: 'neutral0',
    defaultBorderWidth: '40'
  },
  '270':
  {
    defaultBackground: 'neutral',
    defaultForeground: 'neutral',
    defaultTintForeground: 'tint'
  },
  '271': { '50': 1.8, '60': 2.15, s50: 1.15, s60: 1.45 },
  '272': { '50': 2.15, '60': 2.4, s50: 1.15, s60: 1.45 },
  '273': { '50': 2.4, '60': 2.85, s50: 1.15, s60: 1.45 },
  '274':
  {
    shadowColor: 'rgba(5, 32, 46, 1)',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.12,
    shadowRadius: 16,
    elevation: 2
  },
  '275':
  {
    shadowColor: 'rgba(5, 32, 46, 1)',
    shadowOffset: { width: 0, height: 8 },
    shadowOpacity: 0.12,
    shadowRadius: 24,
    elevation: 4
  }
} as const;

export const defaultColorScheme = 'light' as const;

export const colorSchemes = {
  light:
  {
    backgrounds:
    {
      neutral0:
      {
        color: '#ffffff',
        readableColor: 'black',
        foregrounds: __CACHE__["0"],
        strokes: __CACHE__["1"]
      },
      neutral25:
      {
        color: '#f8f8f7',
        readableColor: 'black',
        foregrounds: __CACHE__["0"],
        strokes: __CACHE__["1"]
      },
      neutral:
      {
        color: '#f2f1ee',
        readableColor: 'black',
        foregrounds: __CACHE__["0"],
        strokes: __CACHE__["1"]
      },
      neutral75:
      {
        color: '#ebe9e5',
        readableColor: 'black',
        foregrounds: __CACHE__["0"],
        strokes: __CACHE__["1"]
      },
      tint:
      {
        color: '#0065de',
        readableColor: 'white',
        foregrounds: __CACHE__["2"],
        strokes: __CACHE__["3"]
      },
      'tint-dim':
      {
        color: 'rgba(177,197,255,0.4)',
        readableColor: 'black',
        foregrounds: __CACHE__["4"],
        strokes: __CACHE__["5"]
      },
      success:
      {
        color: '#00c388',
        readableColor: 'black',
        foregrounds: __CACHE__["6"],
        strokes: __CACHE__["7"]
      },
      'success-dim':
      {
        color: 'rgba(104,219,169,0.4)',
        readableColor: 'black',
        foregrounds: __CACHE__["8"],
        strokes: __CACHE__["9"]
      },
      error:
      {
        color: '#d01e2a',
        readableColor: 'white',
        foregrounds: __CACHE__["10"],
        strokes: __CACHE__["11"]
      },
      'error-dim':
      {
        color: 'rgba(250,181,182,0.4)',
        readableColor: 'black',
        foregrounds: __CACHE__["12"],
        strokes: __CACHE__["13"]
      },
      info:
      {
        color: '#9c0bff',
        readableColor: 'white',
        foregrounds: __CACHE__["14"],
        strokes: __CACHE__["15"]
      },
      'info-dim':
      {
        color: 'rgba(212,187,255,0.4)',
        readableColor: 'black',
        foregrounds: __CACHE__["16"],
        strokes: __CACHE__["17"]
      },
      warning:
      {
        color: '#ed9900',
        readableColor: 'black',
        foregrounds: __CACHE__["18"],
        strokes: __CACHE__["19"]
      },
      'warning-dim':
      {
        color: 'rgba(255,184,107,0.4)',
        readableColor: 'black',
        foregrounds: __CACHE__["20"],
        strokes: __CACHE__["21"]
      }
    }
  },
  dark:
  {
    backgrounds:
    {
      neutral0:
      {
        color: '#32302b',
        readableColor: 'white',
        foregrounds: __CACHE__["22"],
        strokes: __CACHE__["23"]
      },
      neutral25:
      {
        color: '#272622',
        readableColor: 'white',
        foregrounds: __CACHE__["22"],
        strokes: __CACHE__["23"]
      },
      neutral:
      {
        color: '#1d1b18',
        readableColor: 'white',
        foregrounds: __CACHE__["22"],
        strokes: __CACHE__["23"]
      },
      neutral75:
      {
        color: '#12110e',
        readableColor: 'white',
        foregrounds: __CACHE__["22"],
        strokes: __CACHE__["23"]
      },
      tint:
      {
        color: '#0065de',
        readableColor: 'white',
        foregrounds: __CACHE__["2"],
        strokes: __CACHE__["3"]
      },
      'tint-dim':
      {
        color: 'rgba(0,42,120,0.4)',
        readableColor: 'white',
        foregrounds: __CACHE__["24"],
        strokes: __CACHE__["25"]
      },
      success:
      {
        color: '#00c388',
        readableColor: 'black',
        foregrounds: __CACHE__["6"],
        strokes: __CACHE__["7"]
      },
      'success-dim':
      {
        color: 'rgba(0,56,36,0.4)',
        readableColor: 'white',
        foregrounds: __CACHE__["26"],
        strokes: __CACHE__["27"]
      },
      error:
      {
        color: '#d01e2a',
        readableColor: 'white',
        foregrounds: __CACHE__["10"],
        strokes: __CACHE__["11"]
      },
      'error-dim':
      {
        color: 'rgba(101,9,15,0.4)',
        readableColor: 'white',
        foregrounds: __CACHE__["28"],
        strokes: __CACHE__["29"]
      },
      info:
      {
        color: '#9c0bff',
        readableColor: 'white',
        foregrounds: __CACHE__["14"],
        strokes: __CACHE__["15"]
      },
      'info-dim':
      {
        color: 'rgba(74,0,126,0.4)',
        readableColor: 'white',
        foregrounds: __CACHE__["30"],
        strokes: __CACHE__["31"]
      },
      warning:
      {
        color: '#ed9900',
        readableColor: 'black',
        foregrounds: __CACHE__["18"],
        strokes: __CACHE__["19"]
      },
      'warning-dim':
      {
        color: 'rgba(83,34,0,0.4)',
        readableColor: 'white',
        foregrounds: __CACHE__["32"],
        strokes: __CACHE__["33"]
      }
    }
  }
} as const;

export const colorSchemeStyles = {
  light:
  {
    'background-neutral0-color': __CACHE__["34"],
    'background-neutral0-foregrounds-neutral0': __CACHE__["35"],
    'background-neutral0-foregrounds-neutral': __CACHE__["36"],
    'background-neutral0-foregrounds-neutral100': __CACHE__["37"],
    'background-neutral0-foregrounds-tint': __CACHE__["38"],
    'background-neutral0-foregrounds-success': __CACHE__["39"],
    'background-neutral0-foregrounds-error': __CACHE__["40"],
    'background-neutral0-foregrounds-info': __CACHE__["41"],
    'background-neutral0-foregrounds-warning': __CACHE__["42"],
    'background-neutral0-strokes-neutral0': __CACHE__["43"],
    'background-neutral0-strokes-neutral0-background': __CACHE__["44"],
    'background-neutral0-strokes-neutral': __CACHE__["45"],
    'background-neutral0-strokes-neutral-background': __CACHE__["46"],
    'background-neutral0-strokes-neutral100': __CACHE__["47"],
    'background-neutral0-strokes-neutral100-background': __CACHE__["48"],
    'background-neutral0-strokes-tint': __CACHE__["49"],
    'background-neutral0-strokes-tint-background': __CACHE__["50"],
    'background-neutral0-strokes-tint100': __CACHE__["51"],
    'background-neutral0-strokes-tint100-background': __CACHE__["52"],
    'background-neutral0-strokes-success': __CACHE__["53"],
    'background-neutral0-strokes-success-background': __CACHE__["54"],
    'background-neutral0-strokes-success100': __CACHE__["55"],
    'background-neutral0-strokes-success100-background': __CACHE__["56"],
    'background-neutral0-strokes-error': __CACHE__["57"],
    'background-neutral0-strokes-error-background': __CACHE__["58"],
    'background-neutral0-strokes-error100': __CACHE__["59"],
    'background-neutral0-strokes-error100-background': __CACHE__["60"],
    'background-neutral0-strokes-info': __CACHE__["61"],
    'background-neutral0-strokes-info-background': __CACHE__["62"],
    'background-neutral0-strokes-info100': __CACHE__["63"],
    'background-neutral0-strokes-info100-background': __CACHE__["64"],
    'background-neutral0-strokes-warning': __CACHE__["65"],
    'background-neutral0-strokes-warning-background': __CACHE__["66"],
    'background-neutral0-strokes-warning100': __CACHE__["67"],
    'background-neutral0-strokes-warning100-background': __CACHE__["68"],
    'background-neutral25-color': __CACHE__["69"],
    'background-neutral25-foregrounds-neutral0': __CACHE__["35"],
    'background-neutral25-foregrounds-neutral': __CACHE__["36"],
    'background-neutral25-foregrounds-neutral100': __CACHE__["37"],
    'background-neutral25-foregrounds-tint': __CACHE__["38"],
    'background-neutral25-foregrounds-success': __CACHE__["39"],
    'background-neutral25-foregrounds-error': __CACHE__["40"],
    'background-neutral25-foregrounds-info': __CACHE__["41"],
    'background-neutral25-foregrounds-warning': __CACHE__["42"],
    'background-neutral25-strokes-neutral0': __CACHE__["43"],
    'background-neutral25-strokes-neutral0-background': __CACHE__["44"],
    'background-neutral25-strokes-neutral': __CACHE__["45"],
    'background-neutral25-strokes-neutral-background': __CACHE__["46"],
    'background-neutral25-strokes-neutral100': __CACHE__["47"],
    'background-neutral25-strokes-neutral100-background': __CACHE__["48"],
    'background-neutral25-strokes-tint': __CACHE__["49"],
    'background-neutral25-strokes-tint-background': __CACHE__["50"],
    'background-neutral25-strokes-tint100': __CACHE__["51"],
    'background-neutral25-strokes-tint100-background': __CACHE__["52"],
    'background-neutral25-strokes-success': __CACHE__["53"],
    'background-neutral25-strokes-success-background': __CACHE__["54"],
    'background-neutral25-strokes-success100': __CACHE__["55"],
    'background-neutral25-strokes-success100-background': __CACHE__["56"],
    'background-neutral25-strokes-error': __CACHE__["57"],
    'background-neutral25-strokes-error-background': __CACHE__["58"],
    'background-neutral25-strokes-error100': __CACHE__["59"],
    'background-neutral25-strokes-error100-background': __CACHE__["60"],
    'background-neutral25-strokes-info': __CACHE__["61"],
    'background-neutral25-strokes-info-background': __CACHE__["62"],
    'background-neutral25-strokes-info100': __CACHE__["63"],
    'background-neutral25-strokes-info100-background': __CACHE__["64"],
    'background-neutral25-strokes-warning': __CACHE__["65"],
    'background-neutral25-strokes-warning-background': __CACHE__["66"],
    'background-neutral25-strokes-warning100': __CACHE__["67"],
    'background-neutral25-strokes-warning100-background': __CACHE__["68"],
    'background-neutral-color': __CACHE__["70"],
    'background-neutral-foregrounds-neutral0': __CACHE__["35"],
    'background-neutral-foregrounds-neutral': __CACHE__["36"],
    'background-neutral-foregrounds-neutral100': __CACHE__["37"],
    'background-neutral-foregrounds-tint': __CACHE__["38"],
    'background-neutral-foregrounds-success': __CACHE__["39"],
    'background-neutral-foregrounds-error': __CACHE__["40"],
    'background-neutral-foregrounds-info': __CACHE__["41"],
    'background-neutral-foregrounds-warning': __CACHE__["42"],
    'background-neutral-strokes-neutral0': __CACHE__["43"],
    'background-neutral-strokes-neutral0-background': __CACHE__["44"],
    'background-neutral-strokes-neutral': __CACHE__["45"],
    'background-neutral-strokes-neutral-background': __CACHE__["46"],
    'background-neutral-strokes-neutral100': __CACHE__["47"],
    'background-neutral-strokes-neutral100-background': __CACHE__["48"],
    'background-neutral-strokes-tint': __CACHE__["49"],
    'background-neutral-strokes-tint-background': __CACHE__["50"],
    'background-neutral-strokes-tint100': __CACHE__["51"],
    'background-neutral-strokes-tint100-background': __CACHE__["52"],
    'background-neutral-strokes-success': __CACHE__["53"],
    'background-neutral-strokes-success-background': __CACHE__["54"],
    'background-neutral-strokes-success100': __CACHE__["55"],
    'background-neutral-strokes-success100-background': __CACHE__["56"],
    'background-neutral-strokes-error': __CACHE__["57"],
    'background-neutral-strokes-error-background': __CACHE__["58"],
    'background-neutral-strokes-error100': __CACHE__["59"],
    'background-neutral-strokes-error100-background': __CACHE__["60"],
    'background-neutral-strokes-info': __CACHE__["61"],
    'background-neutral-strokes-info-background': __CACHE__["62"],
    'background-neutral-strokes-info100': __CACHE__["63"],
    'background-neutral-strokes-info100-background': __CACHE__["64"],
    'background-neutral-strokes-warning': __CACHE__["65"],
    'background-neutral-strokes-warning-background': __CACHE__["66"],
    'background-neutral-strokes-warning100': __CACHE__["67"],
    'background-neutral-strokes-warning100-background': __CACHE__["68"],
    'background-neutral75-color': __CACHE__["71"],
    'background-neutral75-foregrounds-neutral0': __CACHE__["35"],
    'background-neutral75-foregrounds-neutral': __CACHE__["36"],
    'background-neutral75-foregrounds-neutral100': __CACHE__["37"],
    'background-neutral75-foregrounds-tint': __CACHE__["38"],
    'background-neutral75-foregrounds-success': __CACHE__["39"],
    'background-neutral75-foregrounds-error': __CACHE__["40"],
    'background-neutral75-foregrounds-info': __CACHE__["41"],
    'background-neutral75-foregrounds-warning': __CACHE__["42"],
    'background-neutral75-strokes-neutral0': __CACHE__["43"],
    'background-neutral75-strokes-neutral0-background': __CACHE__["44"],
    'background-neutral75-strokes-neutral': __CACHE__["45"],
    'background-neutral75-strokes-neutral-background': __CACHE__["46"],
    'background-neutral75-strokes-neutral100': __CACHE__["47"],
    'background-neutral75-strokes-neutral100-background': __CACHE__["48"],
    'background-neutral75-strokes-tint': __CACHE__["49"],
    'background-neutral75-strokes-tint-background': __CACHE__["50"],
    'background-neutral75-strokes-tint100': __CACHE__["51"],
    'background-neutral75-strokes-tint100-background': __CACHE__["52"],
    'background-neutral75-strokes-success': __CACHE__["53"],
    'background-neutral75-strokes-success-background': __CACHE__["54"],
    'background-neutral75-strokes-success100': __CACHE__["55"],
    'background-neutral75-strokes-success100-background': __CACHE__["56"],
    'background-neutral75-strokes-error': __CACHE__["57"],
    'background-neutral75-strokes-error-background': __CACHE__["58"],
    'background-neutral75-strokes-error100': __CACHE__["59"],
    'background-neutral75-strokes-error100-background': __CACHE__["60"],
    'background-neutral75-strokes-info': __CACHE__["61"],
    'background-neutral75-strokes-info-background': __CACHE__["62"],
    'background-neutral75-strokes-info100': __CACHE__["63"],
    'background-neutral75-strokes-info100-background': __CACHE__["64"],
    'background-neutral75-strokes-warning': __CACHE__["65"],
    'background-neutral75-strokes-warning-background': __CACHE__["66"],
    'background-neutral75-strokes-warning100': __CACHE__["67"],
    'background-neutral75-strokes-warning100-background': __CACHE__["68"],
    'background-tint-color': __CACHE__["72"],
    'background-tint-foregrounds-neutral0': __CACHE__["73"],
    'background-tint-foregrounds-neutral': __CACHE__["73"],
    'background-tint-foregrounds-neutral100': __CACHE__["74"],
    'background-tint-strokes-neutral0': __CACHE__["75"],
    'background-tint-strokes-neutral0-background': __CACHE__["76"],
    'background-tint-strokes-neutral': __CACHE__["77"],
    'background-tint-strokes-neutral-background': __CACHE__["78"],
    'background-tint-strokes-neutral100': __CACHE__["79"],
    'background-tint-strokes-neutral100-background': __CACHE__["80"],
    'background-tint-dim-color': __CACHE__["81"],
    'background-tint-dim-foregrounds-neutral0': __CACHE__["38"],
    'background-tint-dim-foregrounds-neutral': __CACHE__["82"],
    'background-tint-dim-foregrounds-neutral100': __CACHE__["83"],
    'background-tint-dim-strokes-neutral0': __CACHE__["84"],
    'background-tint-dim-strokes-neutral0-background': __CACHE__["85"],
    'background-tint-dim-strokes-neutral': __CACHE__["86"],
    'background-tint-dim-strokes-neutral-background': __CACHE__["87"],
    'background-tint-dim-strokes-neutral100': __CACHE__["88"],
    'background-tint-dim-strokes-neutral100-background': __CACHE__["89"],
    'background-success-color': __CACHE__["90"],
    'background-success-foregrounds-neutral0': __CACHE__["91"],
    'background-success-foregrounds-neutral': __CACHE__["92"],
    'background-success-foregrounds-neutral100': __CACHE__["93"],
    'background-success-strokes-neutral0': __CACHE__["94"],
    'background-success-strokes-neutral0-background': __CACHE__["95"],
    'background-success-strokes-neutral': __CACHE__["96"],
    'background-success-strokes-neutral-background': __CACHE__["97"],
    'background-success-strokes-neutral100': __CACHE__["98"],
    'background-success-strokes-neutral100-background': __CACHE__["99"],
    'background-success-dim-color': __CACHE__["100"],
    'background-success-dim-foregrounds-neutral0': __CACHE__["39"],
    'background-success-dim-foregrounds-neutral': __CACHE__["101"],
    'background-success-dim-foregrounds-neutral100': __CACHE__["93"],
    'background-success-dim-strokes-neutral0': __CACHE__["102"],
    'background-success-dim-strokes-neutral0-background': __CACHE__["103"],
    'background-success-dim-strokes-neutral': __CACHE__["104"],
    'background-success-dim-strokes-neutral-background': __CACHE__["105"],
    'background-success-dim-strokes-neutral100': __CACHE__["106"],
    'background-success-dim-strokes-neutral100-background': __CACHE__["107"],
    'background-error-color': __CACHE__["108"],
    'background-error-foregrounds-neutral0': __CACHE__["109"],
    'background-error-foregrounds-neutral': __CACHE__["109"],
    'background-error-foregrounds-neutral100': __CACHE__["74"],
    'background-error-strokes-neutral0': __CACHE__["110"],
    'background-error-strokes-neutral0-background': __CACHE__["111"],
    'background-error-strokes-neutral': __CACHE__["112"],
    'background-error-strokes-neutral-background': __CACHE__["113"],
    'background-error-strokes-neutral100': __CACHE__["114"],
    'background-error-strokes-neutral100-background': __CACHE__["115"],
    'background-error-dim-color': __CACHE__["116"],
    'background-error-dim-foregrounds-neutral0': __CACHE__["40"],
    'background-error-dim-foregrounds-neutral': __CACHE__["117"],
    'background-error-dim-foregrounds-neutral100': __CACHE__["118"],
    'background-error-dim-strokes-neutral0': __CACHE__["119"],
    'background-error-dim-strokes-neutral0-background': __CACHE__["120"],
    'background-error-dim-strokes-neutral': __CACHE__["121"],
    'background-error-dim-strokes-neutral-background': __CACHE__["122"],
    'background-error-dim-strokes-neutral100': __CACHE__["123"],
    'background-error-dim-strokes-neutral100-background': __CACHE__["124"],
    'background-info-color': __CACHE__["125"],
    'background-info-foregrounds-neutral0': __CACHE__["126"],
    'background-info-foregrounds-neutral': __CACHE__["126"],
    'background-info-foregrounds-neutral100': __CACHE__["74"],
    'background-info-strokes-neutral0': __CACHE__["127"],
    'background-info-strokes-neutral0-background': __CACHE__["128"],
    'background-info-strokes-neutral': __CACHE__["129"],
    'background-info-strokes-neutral-background': __CACHE__["130"],
    'background-info-strokes-neutral100': __CACHE__["131"],
    'background-info-strokes-neutral100-background': __CACHE__["132"],
    'background-info-dim-color': __CACHE__["133"],
    'background-info-dim-foregrounds-neutral0': __CACHE__["41"],
    'background-info-dim-foregrounds-neutral': __CACHE__["134"],
    'background-info-dim-foregrounds-neutral100': __CACHE__["135"],
    'background-info-dim-strokes-neutral0': __CACHE__["136"],
    'background-info-dim-strokes-neutral0-background': __CACHE__["137"],
    'background-info-dim-strokes-neutral': __CACHE__["138"],
    'background-info-dim-strokes-neutral-background': __CACHE__["139"],
    'background-info-dim-strokes-neutral100': __CACHE__["140"],
    'background-info-dim-strokes-neutral100-background': __CACHE__["141"],
    'background-warning-color': __CACHE__["142"],
    'background-warning-foregrounds-neutral0': __CACHE__["143"],
    'background-warning-foregrounds-neutral': __CACHE__["144"],
    'background-warning-foregrounds-neutral100': __CACHE__["145"],
    'background-warning-strokes-neutral0': __CACHE__["146"],
    'background-warning-strokes-neutral0-background': __CACHE__["147"],
    'background-warning-strokes-neutral': __CACHE__["148"],
    'background-warning-strokes-neutral-background': __CACHE__["149"],
    'background-warning-strokes-neutral100': __CACHE__["150"],
    'background-warning-strokes-neutral100-background': __CACHE__["151"],
    'background-warning-dim-color': __CACHE__["152"],
    'background-warning-dim-foregrounds-neutral0': __CACHE__["42"],
    'background-warning-dim-foregrounds-neutral': __CACHE__["153"],
    'background-warning-dim-foregrounds-neutral100': __CACHE__["145"],
    'background-warning-dim-strokes-neutral0': __CACHE__["154"],
    'background-warning-dim-strokes-neutral0-background': __CACHE__["155"],
    'background-warning-dim-strokes-neutral': __CACHE__["156"],
    'background-warning-dim-strokes-neutral-background': __CACHE__["157"],
    'background-warning-dim-strokes-neutral100': __CACHE__["158"],
    'background-warning-dim-strokes-neutral100-background': __CACHE__["159"]
  },
  dark:
  {
    'background-neutral0-color': __CACHE__["160"],
    'background-neutral0-foregrounds-neutral0': __CACHE__["161"],
    'background-neutral0-foregrounds-neutral': __CACHE__["162"],
    'background-neutral0-foregrounds-neutral100': __CACHE__["74"],
    'background-neutral0-foregrounds-tint': __CACHE__["163"],
    'background-neutral0-foregrounds-success': __CACHE__["164"],
    'background-neutral0-foregrounds-error': __CACHE__["165"],
    'background-neutral0-foregrounds-info': __CACHE__["166"],
    'background-neutral0-foregrounds-warning': __CACHE__["167"],
    'background-neutral0-strokes-neutral0': __CACHE__["168"],
    'background-neutral0-strokes-neutral0-background': __CACHE__["169"],
    'background-neutral0-strokes-neutral': __CACHE__["170"],
    'background-neutral0-strokes-neutral-background': __CACHE__["171"],
    'background-neutral0-strokes-neutral100': __CACHE__["172"],
    'background-neutral0-strokes-neutral100-background': __CACHE__["173"],
    'background-neutral0-strokes-tint': __CACHE__["174"],
    'background-neutral0-strokes-tint-background': __CACHE__["175"],
    'background-neutral0-strokes-tint100': __CACHE__["176"],
    'background-neutral0-strokes-tint100-background': __CACHE__["177"],
    'background-neutral0-strokes-success': __CACHE__["178"],
    'background-neutral0-strokes-success-background': __CACHE__["179"],
    'background-neutral0-strokes-success100': __CACHE__["180"],
    'background-neutral0-strokes-success100-background': __CACHE__["181"],
    'background-neutral0-strokes-error': __CACHE__["182"],
    'background-neutral0-strokes-error-background': __CACHE__["183"],
    'background-neutral0-strokes-error100': __CACHE__["184"],
    'background-neutral0-strokes-error100-background': __CACHE__["185"],
    'background-neutral0-strokes-info': __CACHE__["186"],
    'background-neutral0-strokes-info-background': __CACHE__["187"],
    'background-neutral0-strokes-info100': __CACHE__["188"],
    'background-neutral0-strokes-info100-background': __CACHE__["189"],
    'background-neutral0-strokes-warning': __CACHE__["190"],
    'background-neutral0-strokes-warning-background': __CACHE__["191"],
    'background-neutral0-strokes-warning100': __CACHE__["192"],
    'background-neutral0-strokes-warning100-background': __CACHE__["193"],
    'background-neutral25-color': __CACHE__["194"],
    'background-neutral25-foregrounds-neutral0': __CACHE__["161"],
    'background-neutral25-foregrounds-neutral': __CACHE__["162"],
    'background-neutral25-foregrounds-neutral100': __CACHE__["74"],
    'background-neutral25-foregrounds-tint': __CACHE__["163"],
    'background-neutral25-foregrounds-success': __CACHE__["164"],
    'background-neutral25-foregrounds-error': __CACHE__["165"],
    'background-neutral25-foregrounds-info': __CACHE__["166"],
    'background-neutral25-foregrounds-warning': __CACHE__["167"],
    'background-neutral25-strokes-neutral0': __CACHE__["168"],
    'background-neutral25-strokes-neutral0-background': __CACHE__["169"],
    'background-neutral25-strokes-neutral': __CACHE__["170"],
    'background-neutral25-strokes-neutral-background': __CACHE__["171"],
    'background-neutral25-strokes-neutral100': __CACHE__["172"],
    'background-neutral25-strokes-neutral100-background': __CACHE__["173"],
    'background-neutral25-strokes-tint': __CACHE__["174"],
    'background-neutral25-strokes-tint-background': __CACHE__["175"],
    'background-neutral25-strokes-tint100': __CACHE__["176"],
    'background-neutral25-strokes-tint100-background': __CACHE__["177"],
    'background-neutral25-strokes-success': __CACHE__["178"],
    'background-neutral25-strokes-success-background': __CACHE__["179"],
    'background-neutral25-strokes-success100': __CACHE__["180"],
    'background-neutral25-strokes-success100-background': __CACHE__["181"],
    'background-neutral25-strokes-error': __CACHE__["182"],
    'background-neutral25-strokes-error-background': __CACHE__["183"],
    'background-neutral25-strokes-error100': __CACHE__["184"],
    'background-neutral25-strokes-error100-background': __CACHE__["185"],
    'background-neutral25-strokes-info': __CACHE__["186"],
    'background-neutral25-strokes-info-background': __CACHE__["187"],
    'background-neutral25-strokes-info100': __CACHE__["188"],
    'background-neutral25-strokes-info100-background': __CACHE__["189"],
    'background-neutral25-strokes-warning': __CACHE__["190"],
    'background-neutral25-strokes-warning-background': __CACHE__["191"],
    'background-neutral25-strokes-warning100': __CACHE__["192"],
    'background-neutral25-strokes-warning100-background': __CACHE__["193"],
    'background-neutral-color': __CACHE__["195"],
    'background-neutral-foregrounds-neutral0': __CACHE__["161"],
    'background-neutral-foregrounds-neutral': __CACHE__["162"],
    'background-neutral-foregrounds-neutral100': __CACHE__["74"],
    'background-neutral-foregrounds-tint': __CACHE__["163"],
    'background-neutral-foregrounds-success': __CACHE__["164"],
    'background-neutral-foregrounds-error': __CACHE__["165"],
    'background-neutral-foregrounds-info': __CACHE__["166"],
    'background-neutral-foregrounds-warning': __CACHE__["167"],
    'background-neutral-strokes-neutral0': __CACHE__["168"],
    'background-neutral-strokes-neutral0-background': __CACHE__["169"],
    'background-neutral-strokes-neutral': __CACHE__["170"],
    'background-neutral-strokes-neutral-background': __CACHE__["171"],
    'background-neutral-strokes-neutral100': __CACHE__["172"],
    'background-neutral-strokes-neutral100-background': __CACHE__["173"],
    'background-neutral-strokes-tint': __CACHE__["174"],
    'background-neutral-strokes-tint-background': __CACHE__["175"],
    'background-neutral-strokes-tint100': __CACHE__["176"],
    'background-neutral-strokes-tint100-background': __CACHE__["177"],
    'background-neutral-strokes-success': __CACHE__["178"],
    'background-neutral-strokes-success-background': __CACHE__["179"],
    'background-neutral-strokes-success100': __CACHE__["180"],
    'background-neutral-strokes-success100-background': __CACHE__["181"],
    'background-neutral-strokes-error': __CACHE__["182"],
    'background-neutral-strokes-error-background': __CACHE__["183"],
    'background-neutral-strokes-error100': __CACHE__["184"],
    'background-neutral-strokes-error100-background': __CACHE__["185"],
    'background-neutral-strokes-info': __CACHE__["186"],
    'background-neutral-strokes-info-background': __CACHE__["187"],
    'background-neutral-strokes-info100': __CACHE__["188"],
    'background-neutral-strokes-info100-background': __CACHE__["189"],
    'background-neutral-strokes-warning': __CACHE__["190"],
    'background-neutral-strokes-warning-background': __CACHE__["191"],
    'background-neutral-strokes-warning100': __CACHE__["192"],
    'background-neutral-strokes-warning100-background': __CACHE__["193"],
    'background-neutral75-color': __CACHE__["196"],
    'background-neutral75-foregrounds-neutral0': __CACHE__["161"],
    'background-neutral75-foregrounds-neutral': __CACHE__["162"],
    'background-neutral75-foregrounds-neutral100': __CACHE__["74"],
    'background-neutral75-foregrounds-tint': __CACHE__["163"],
    'background-neutral75-foregrounds-success': __CACHE__["164"],
    'background-neutral75-foregrounds-error': __CACHE__["165"],
    'background-neutral75-foregrounds-info': __CACHE__["166"],
    'background-neutral75-foregrounds-warning': __CACHE__["167"],
    'background-neutral75-strokes-neutral0': __CACHE__["168"],
    'background-neutral75-strokes-neutral0-background': __CACHE__["169"],
    'background-neutral75-strokes-neutral': __CACHE__["170"],
    'background-neutral75-strokes-neutral-background': __CACHE__["171"],
    'background-neutral75-strokes-neutral100': __CACHE__["172"],
    'background-neutral75-strokes-neutral100-background': __CACHE__["173"],
    'background-neutral75-strokes-tint': __CACHE__["174"],
    'background-neutral75-strokes-tint-background': __CACHE__["175"],
    'background-neutral75-strokes-tint100': __CACHE__["176"],
    'background-neutral75-strokes-tint100-background': __CACHE__["177"],
    'background-neutral75-strokes-success': __CACHE__["178"],
    'background-neutral75-strokes-success-background': __CACHE__["179"],
    'background-neutral75-strokes-success100': __CACHE__["180"],
    'background-neutral75-strokes-success100-background': __CACHE__["181"],
    'background-neutral75-strokes-error': __CACHE__["182"],
    'background-neutral75-strokes-error-background': __CACHE__["183"],
    'background-neutral75-strokes-error100': __CACHE__["184"],
    'background-neutral75-strokes-error100-background': __CACHE__["185"],
    'background-neutral75-strokes-info': __CACHE__["186"],
    'background-neutral75-strokes-info-background': __CACHE__["187"],
    'background-neutral75-strokes-info100': __CACHE__["188"],
    'background-neutral75-strokes-info100-background': __CACHE__["189"],
    'background-neutral75-strokes-warning': __CACHE__["190"],
    'background-neutral75-strokes-warning-background': __CACHE__["191"],
    'background-neutral75-strokes-warning100': __CACHE__["192"],
    'background-neutral75-strokes-warning100-background': __CACHE__["193"],
    'background-tint-color': __CACHE__["72"],
    'background-tint-foregrounds-neutral0': __CACHE__["73"],
    'background-tint-foregrounds-neutral': __CACHE__["73"],
    'background-tint-foregrounds-neutral100': __CACHE__["74"],
    'background-tint-strokes-neutral0': __CACHE__["75"],
    'background-tint-strokes-neutral0-background': __CACHE__["76"],
    'background-tint-strokes-neutral': __CACHE__["77"],
    'background-tint-strokes-neutral-background': __CACHE__["78"],
    'background-tint-strokes-neutral100': __CACHE__["79"],
    'background-tint-strokes-neutral100-background': __CACHE__["80"],
    'background-tint-dim-color': __CACHE__["197"],
    'background-tint-dim-foregrounds-neutral0': __CACHE__["163"],
    'background-tint-dim-foregrounds-neutral': __CACHE__["198"],
    'background-tint-dim-foregrounds-neutral100': __CACHE__["74"],
    'background-tint-dim-strokes-neutral0': __CACHE__["199"],
    'background-tint-dim-strokes-neutral0-background': __CACHE__["200"],
    'background-tint-dim-strokes-neutral': __CACHE__["201"],
    'background-tint-dim-strokes-neutral-background': __CACHE__["81"],
    'background-tint-dim-strokes-neutral100': __CACHE__["202"],
    'background-tint-dim-strokes-neutral100-background': __CACHE__["203"],
    'background-success-color': __CACHE__["90"],
    'background-success-foregrounds-neutral0': __CACHE__["91"],
    'background-success-foregrounds-neutral': __CACHE__["92"],
    'background-success-foregrounds-neutral100': __CACHE__["93"],
    'background-success-strokes-neutral0': __CACHE__["94"],
    'background-success-strokes-neutral0-background': __CACHE__["95"],
    'background-success-strokes-neutral': __CACHE__["96"],
    'background-success-strokes-neutral-background': __CACHE__["97"],
    'background-success-strokes-neutral100': __CACHE__["98"],
    'background-success-strokes-neutral100-background': __CACHE__["99"],
    'background-success-dim-color': __CACHE__["204"],
    'background-success-dim-foregrounds-neutral0': __CACHE__["164"],
    'background-success-dim-foregrounds-neutral': __CACHE__["205"],
    'background-success-dim-foregrounds-neutral100': __CACHE__["74"],
    'background-success-dim-strokes-neutral0': __CACHE__["206"],
    'background-success-dim-strokes-neutral0-background': __CACHE__["207"],
    'background-success-dim-strokes-neutral': __CACHE__["208"],
    'background-success-dim-strokes-neutral-background': __CACHE__["100"],
    'background-success-dim-strokes-neutral100': __CACHE__["209"],
    'background-success-dim-strokes-neutral100-background': __CACHE__["210"],
    'background-error-color': __CACHE__["108"],
    'background-error-foregrounds-neutral0': __CACHE__["109"],
    'background-error-foregrounds-neutral': __CACHE__["109"],
    'background-error-foregrounds-neutral100': __CACHE__["74"],
    'background-error-strokes-neutral0': __CACHE__["110"],
    'background-error-strokes-neutral0-background': __CACHE__["111"],
    'background-error-strokes-neutral': __CACHE__["112"],
    'background-error-strokes-neutral-background': __CACHE__["113"],
    'background-error-strokes-neutral100': __CACHE__["114"],
    'background-error-strokes-neutral100-background': __CACHE__["115"],
    'background-error-dim-color': __CACHE__["211"],
    'background-error-dim-foregrounds-neutral0': __CACHE__["165"],
    'background-error-dim-foregrounds-neutral': __CACHE__["212"],
    'background-error-dim-foregrounds-neutral100': __CACHE__["74"],
    'background-error-dim-strokes-neutral0': __CACHE__["213"],
    'background-error-dim-strokes-neutral0-background': __CACHE__["214"],
    'background-error-dim-strokes-neutral': __CACHE__["215"],
    'background-error-dim-strokes-neutral-background': __CACHE__["116"],
    'background-error-dim-strokes-neutral100': __CACHE__["216"],
    'background-error-dim-strokes-neutral100-background': __CACHE__["217"],
    'background-info-color': __CACHE__["125"],
    'background-info-foregrounds-neutral0': __CACHE__["126"],
    'background-info-foregrounds-neutral': __CACHE__["126"],
    'background-info-foregrounds-neutral100': __CACHE__["74"],
    'background-info-strokes-neutral0': __CACHE__["127"],
    'background-info-strokes-neutral0-background': __CACHE__["128"],
    'background-info-strokes-neutral': __CACHE__["129"],
    'background-info-strokes-neutral-background': __CACHE__["130"],
    'background-info-strokes-neutral100': __CACHE__["131"],
    'background-info-strokes-neutral100-background': __CACHE__["132"],
    'background-info-dim-color': __CACHE__["218"],
    'background-info-dim-foregrounds-neutral0': __CACHE__["166"],
    'background-info-dim-foregrounds-neutral': __CACHE__["219"],
    'background-info-dim-foregrounds-neutral100': __CACHE__["74"],
    'background-info-dim-strokes-neutral0': __CACHE__["220"],
    'background-info-dim-strokes-neutral0-background': __CACHE__["221"],
    'background-info-dim-strokes-neutral': __CACHE__["222"],
    'background-info-dim-strokes-neutral-background': __CACHE__["133"],
    'background-info-dim-strokes-neutral100': __CACHE__["223"],
    'background-info-dim-strokes-neutral100-background': __CACHE__["224"],
    'background-warning-color': __CACHE__["142"],
    'background-warning-foregrounds-neutral0': __CACHE__["143"],
    'background-warning-foregrounds-neutral': __CACHE__["144"],
    'background-warning-foregrounds-neutral100': __CACHE__["145"],
    'background-warning-strokes-neutral0': __CACHE__["146"],
    'background-warning-strokes-neutral0-background': __CACHE__["147"],
    'background-warning-strokes-neutral': __CACHE__["148"],
    'background-warning-strokes-neutral-background': __CACHE__["149"],
    'background-warning-strokes-neutral100': __CACHE__["150"],
    'background-warning-strokes-neutral100-background': __CACHE__["151"],
    'background-warning-dim-color': __CACHE__["225"],
    'background-warning-dim-foregrounds-neutral0': __CACHE__["167"],
    'background-warning-dim-foregrounds-neutral': __CACHE__["226"],
    'background-warning-dim-foregrounds-neutral100': __CACHE__["74"],
    'background-warning-dim-strokes-neutral0': __CACHE__["227"],
    'background-warning-dim-strokes-neutral0-background': __CACHE__["228"],
    'background-warning-dim-strokes-neutral': __CACHE__["229"],
    'background-warning-dim-strokes-neutral-background': __CACHE__["152"],
    'background-warning-dim-strokes-neutral100': __CACHE__["230"],
    'background-warning-dim-strokes-neutral100-background': __CACHE__["231"]
  }
} as const;

export const colorSchemeCSS = {
  '.color-scheme-light':
  {
    '[data-color-scheme~="background-neutral0-color"]': __CACHE__["34"],
    '[data-color-scheme~="background-neutral0-foregrounds-neutral0"]': __CACHE__["35"],
    '[data-color-scheme~="background-neutral0-foregrounds-neutral"]': __CACHE__["36"],
    '[data-color-scheme~="background-neutral0-foregrounds-neutral100"]': __CACHE__["37"],
    '[data-color-scheme~="background-neutral0-foregrounds-tint"]': __CACHE__["38"],
    '[data-color-scheme~="background-neutral0-foregrounds-success"]': __CACHE__["39"],
    '[data-color-scheme~="background-neutral0-foregrounds-error"]': __CACHE__["40"],
    '[data-color-scheme~="background-neutral0-foregrounds-info"]': __CACHE__["41"],
    '[data-color-scheme~="background-neutral0-foregrounds-warning"]': __CACHE__["42"],
    '[data-color-scheme~="background-neutral0-strokes-neutral0"]': __CACHE__["43"],
    '[data-color-scheme~="background-neutral0-strokes-neutral0-background"]': __CACHE__["44"],
    '[data-color-scheme~="background-neutral0-strokes-neutral"]': __CACHE__["45"],
    '[data-color-scheme~="background-neutral0-strokes-neutral-background"]': __CACHE__["46"],
    '[data-color-scheme~="background-neutral0-strokes-neutral100"]': __CACHE__["47"],
    '[data-color-scheme~="background-neutral0-strokes-neutral100-background"]': __CACHE__["48"],
    '[data-color-scheme~="background-neutral0-strokes-tint"]': __CACHE__["49"],
    '[data-color-scheme~="background-neutral0-strokes-tint-background"]': __CACHE__["50"],
    '[data-color-scheme~="background-neutral0-strokes-tint100"]': __CACHE__["51"],
    '[data-color-scheme~="background-neutral0-strokes-tint100-background"]': __CACHE__["52"],
    '[data-color-scheme~="background-neutral0-strokes-success"]': __CACHE__["53"],
    '[data-color-scheme~="background-neutral0-strokes-success-background"]': __CACHE__["54"],
    '[data-color-scheme~="background-neutral0-strokes-success100"]': __CACHE__["55"],
    '[data-color-scheme~="background-neutral0-strokes-success100-background"]': __CACHE__["56"],
    '[data-color-scheme~="background-neutral0-strokes-error"]': __CACHE__["57"],
    '[data-color-scheme~="background-neutral0-strokes-error-background"]': __CACHE__["58"],
    '[data-color-scheme~="background-neutral0-strokes-error100"]': __CACHE__["59"],
    '[data-color-scheme~="background-neutral0-strokes-error100-background"]': __CACHE__["60"],
    '[data-color-scheme~="background-neutral0-strokes-info"]': __CACHE__["61"],
    '[data-color-scheme~="background-neutral0-strokes-info-background"]': __CACHE__["62"],
    '[data-color-scheme~="background-neutral0-strokes-info100"]': __CACHE__["63"],
    '[data-color-scheme~="background-neutral0-strokes-info100-background"]': __CACHE__["64"],
    '[data-color-scheme~="background-neutral0-strokes-warning"]': __CACHE__["65"],
    '[data-color-scheme~="background-neutral0-strokes-warning-background"]': __CACHE__["66"],
    '[data-color-scheme~="background-neutral0-strokes-warning100"]': __CACHE__["67"],
    '[data-color-scheme~="background-neutral0-strokes-warning100-background"]': __CACHE__["68"],
    '[data-color-scheme~="background-neutral25-color"]': __CACHE__["69"],
    '[data-color-scheme~="background-neutral25-foregrounds-neutral0"]': __CACHE__["35"],
    '[data-color-scheme~="background-neutral25-foregrounds-neutral"]': __CACHE__["36"],
    '[data-color-scheme~="background-neutral25-foregrounds-neutral100"]': __CACHE__["37"],
    '[data-color-scheme~="background-neutral25-foregrounds-tint"]': __CACHE__["38"],
    '[data-color-scheme~="background-neutral25-foregrounds-success"]': __CACHE__["39"],
    '[data-color-scheme~="background-neutral25-foregrounds-error"]': __CACHE__["40"],
    '[data-color-scheme~="background-neutral25-foregrounds-info"]': __CACHE__["41"],
    '[data-color-scheme~="background-neutral25-foregrounds-warning"]': __CACHE__["42"],
    '[data-color-scheme~="background-neutral25-strokes-neutral0"]': __CACHE__["43"],
    '[data-color-scheme~="background-neutral25-strokes-neutral0-background"]': __CACHE__["44"],
    '[data-color-scheme~="background-neutral25-strokes-neutral"]': __CACHE__["45"],
    '[data-color-scheme~="background-neutral25-strokes-neutral-background"]': __CACHE__["46"],
    '[data-color-scheme~="background-neutral25-strokes-neutral100"]': __CACHE__["47"],
    '[data-color-scheme~="background-neutral25-strokes-neutral100-background"]': __CACHE__["48"],
    '[data-color-scheme~="background-neutral25-strokes-tint"]': __CACHE__["49"],
    '[data-color-scheme~="background-neutral25-strokes-tint-background"]': __CACHE__["50"],
    '[data-color-scheme~="background-neutral25-strokes-tint100"]': __CACHE__["51"],
    '[data-color-scheme~="background-neutral25-strokes-tint100-background"]': __CACHE__["52"],
    '[data-color-scheme~="background-neutral25-strokes-success"]': __CACHE__["53"],
    '[data-color-scheme~="background-neutral25-strokes-success-background"]': __CACHE__["54"],
    '[data-color-scheme~="background-neutral25-strokes-success100"]': __CACHE__["55"],
    '[data-color-scheme~="background-neutral25-strokes-success100-background"]': __CACHE__["56"],
    '[data-color-scheme~="background-neutral25-strokes-error"]': __CACHE__["57"],
    '[data-color-scheme~="background-neutral25-strokes-error-background"]': __CACHE__["58"],
    '[data-color-scheme~="background-neutral25-strokes-error100"]': __CACHE__["59"],
    '[data-color-scheme~="background-neutral25-strokes-error100-background"]': __CACHE__["60"],
    '[data-color-scheme~="background-neutral25-strokes-info"]': __CACHE__["61"],
    '[data-color-scheme~="background-neutral25-strokes-info-background"]': __CACHE__["62"],
    '[data-color-scheme~="background-neutral25-strokes-info100"]': __CACHE__["63"],
    '[data-color-scheme~="background-neutral25-strokes-info100-background"]': __CACHE__["64"],
    '[data-color-scheme~="background-neutral25-strokes-warning"]': __CACHE__["65"],
    '[data-color-scheme~="background-neutral25-strokes-warning-background"]': __CACHE__["66"],
    '[data-color-scheme~="background-neutral25-strokes-warning100"]': __CACHE__["67"],
    '[data-color-scheme~="background-neutral25-strokes-warning100-background"]': __CACHE__["68"],
    '[data-color-scheme~="background-neutral-color"]': __CACHE__["70"],
    '[data-color-scheme~="background-neutral-foregrounds-neutral0"]': __CACHE__["35"],
    '[data-color-scheme~="background-neutral-foregrounds-neutral"]': __CACHE__["36"],
    '[data-color-scheme~="background-neutral-foregrounds-neutral100"]': __CACHE__["37"],
    '[data-color-scheme~="background-neutral-foregrounds-tint"]': __CACHE__["38"],
    '[data-color-scheme~="background-neutral-foregrounds-success"]': __CACHE__["39"],
    '[data-color-scheme~="background-neutral-foregrounds-error"]': __CACHE__["40"],
    '[data-color-scheme~="background-neutral-foregrounds-info"]': __CACHE__["41"],
    '[data-color-scheme~="background-neutral-foregrounds-warning"]': __CACHE__["42"],
    '[data-color-scheme~="background-neutral-strokes-neutral0"]': __CACHE__["43"],
    '[data-color-scheme~="background-neutral-strokes-neutral0-background"]': __CACHE__["44"],
    '[data-color-scheme~="background-neutral-strokes-neutral"]': __CACHE__["45"],
    '[data-color-scheme~="background-neutral-strokes-neutral-background"]': __CACHE__["46"],
    '[data-color-scheme~="background-neutral-strokes-neutral100"]': __CACHE__["47"],
    '[data-color-scheme~="background-neutral-strokes-neutral100-background"]': __CACHE__["48"],
    '[data-color-scheme~="background-neutral-strokes-tint"]': __CACHE__["49"],
    '[data-color-scheme~="background-neutral-strokes-tint-background"]': __CACHE__["50"],
    '[data-color-scheme~="background-neutral-strokes-tint100"]': __CACHE__["51"],
    '[data-color-scheme~="background-neutral-strokes-tint100-background"]': __CACHE__["52"],
    '[data-color-scheme~="background-neutral-strokes-success"]': __CACHE__["53"],
    '[data-color-scheme~="background-neutral-strokes-success-background"]': __CACHE__["54"],
    '[data-color-scheme~="background-neutral-strokes-success100"]': __CACHE__["55"],
    '[data-color-scheme~="background-neutral-strokes-success100-background"]': __CACHE__["56"],
    '[data-color-scheme~="background-neutral-strokes-error"]': __CACHE__["57"],
    '[data-color-scheme~="background-neutral-strokes-error-background"]': __CACHE__["58"],
    '[data-color-scheme~="background-neutral-strokes-error100"]': __CACHE__["59"],
    '[data-color-scheme~="background-neutral-strokes-error100-background"]': __CACHE__["60"],
    '[data-color-scheme~="background-neutral-strokes-info"]': __CACHE__["61"],
    '[data-color-scheme~="background-neutral-strokes-info-background"]': __CACHE__["62"],
    '[data-color-scheme~="background-neutral-strokes-info100"]': __CACHE__["63"],
    '[data-color-scheme~="background-neutral-strokes-info100-background"]': __CACHE__["64"],
    '[data-color-scheme~="background-neutral-strokes-warning"]': __CACHE__["65"],
    '[data-color-scheme~="background-neutral-strokes-warning-background"]': __CACHE__["66"],
    '[data-color-scheme~="background-neutral-strokes-warning100"]': __CACHE__["67"],
    '[data-color-scheme~="background-neutral-strokes-warning100-background"]': __CACHE__["68"],
    '[data-color-scheme~="background-neutral75-color"]': __CACHE__["71"],
    '[data-color-scheme~="background-neutral75-foregrounds-neutral0"]': __CACHE__["35"],
    '[data-color-scheme~="background-neutral75-foregrounds-neutral"]': __CACHE__["36"],
    '[data-color-scheme~="background-neutral75-foregrounds-neutral100"]': __CACHE__["37"],
    '[data-color-scheme~="background-neutral75-foregrounds-tint"]': __CACHE__["38"],
    '[data-color-scheme~="background-neutral75-foregrounds-success"]': __CACHE__["39"],
    '[data-color-scheme~="background-neutral75-foregrounds-error"]': __CACHE__["40"],
    '[data-color-scheme~="background-neutral75-foregrounds-info"]': __CACHE__["41"],
    '[data-color-scheme~="background-neutral75-foregrounds-warning"]': __CACHE__["42"],
    '[data-color-scheme~="background-neutral75-strokes-neutral0"]': __CACHE__["43"],
    '[data-color-scheme~="background-neutral75-strokes-neutral0-background"]': __CACHE__["44"],
    '[data-color-scheme~="background-neutral75-strokes-neutral"]': __CACHE__["45"],
    '[data-color-scheme~="background-neutral75-strokes-neutral-background"]': __CACHE__["46"],
    '[data-color-scheme~="background-neutral75-strokes-neutral100"]': __CACHE__["47"],
    '[data-color-scheme~="background-neutral75-strokes-neutral100-background"]': __CACHE__["48"],
    '[data-color-scheme~="background-neutral75-strokes-tint"]': __CACHE__["49"],
    '[data-color-scheme~="background-neutral75-strokes-tint-background"]': __CACHE__["50"],
    '[data-color-scheme~="background-neutral75-strokes-tint100"]': __CACHE__["51"],
    '[data-color-scheme~="background-neutral75-strokes-tint100-background"]': __CACHE__["52"],
    '[data-color-scheme~="background-neutral75-strokes-success"]': __CACHE__["53"],
    '[data-color-scheme~="background-neutral75-strokes-success-background"]': __CACHE__["54"],
    '[data-color-scheme~="background-neutral75-strokes-success100"]': __CACHE__["55"],
    '[data-color-scheme~="background-neutral75-strokes-success100-background"]': __CACHE__["56"],
    '[data-color-scheme~="background-neutral75-strokes-error"]': __CACHE__["57"],
    '[data-color-scheme~="background-neutral75-strokes-error-background"]': __CACHE__["58"],
    '[data-color-scheme~="background-neutral75-strokes-error100"]': __CACHE__["59"],
    '[data-color-scheme~="background-neutral75-strokes-error100-background"]': __CACHE__["60"],
    '[data-color-scheme~="background-neutral75-strokes-info"]': __CACHE__["61"],
    '[data-color-scheme~="background-neutral75-strokes-info-background"]': __CACHE__["62"],
    '[data-color-scheme~="background-neutral75-strokes-info100"]': __CACHE__["63"],
    '[data-color-scheme~="background-neutral75-strokes-info100-background"]': __CACHE__["64"],
    '[data-color-scheme~="background-neutral75-strokes-warning"]': __CACHE__["65"],
    '[data-color-scheme~="background-neutral75-strokes-warning-background"]': __CACHE__["66"],
    '[data-color-scheme~="background-neutral75-strokes-warning100"]': __CACHE__["67"],
    '[data-color-scheme~="background-neutral75-strokes-warning100-background"]': __CACHE__["68"],
    '[data-color-scheme~="background-tint-color"]': __CACHE__["72"],
    '[data-color-scheme~="background-tint-foregrounds-neutral0"]': __CACHE__["73"],
    '[data-color-scheme~="background-tint-foregrounds-neutral"]': __CACHE__["73"],
    '[data-color-scheme~="background-tint-foregrounds-neutral100"]': __CACHE__["74"],
    '[data-color-scheme~="background-tint-strokes-neutral0"]': __CACHE__["75"],
    '[data-color-scheme~="background-tint-strokes-neutral0-background"]': __CACHE__["76"],
    '[data-color-scheme~="background-tint-strokes-neutral"]': __CACHE__["77"],
    '[data-color-scheme~="background-tint-strokes-neutral-background"]': __CACHE__["78"],
    '[data-color-scheme~="background-tint-strokes-neutral100"]': __CACHE__["79"],
    '[data-color-scheme~="background-tint-strokes-neutral100-background"]': __CACHE__["80"],
    '[data-color-scheme~="background-tint-dim-color"]': __CACHE__["81"],
    '[data-color-scheme~="background-tint-dim-foregrounds-neutral0"]': __CACHE__["38"],
    '[data-color-scheme~="background-tint-dim-foregrounds-neutral"]': __CACHE__["82"],
    '[data-color-scheme~="background-tint-dim-foregrounds-neutral100"]': __CACHE__["83"],
    '[data-color-scheme~="background-tint-dim-strokes-neutral0"]': __CACHE__["84"],
    '[data-color-scheme~="background-tint-dim-strokes-neutral0-background"]': __CACHE__["85"],
    '[data-color-scheme~="background-tint-dim-strokes-neutral"]': __CACHE__["86"],
    '[data-color-scheme~="background-tint-dim-strokes-neutral-background"]': __CACHE__["87"],
    '[data-color-scheme~="background-tint-dim-strokes-neutral100"]': __CACHE__["88"],
    '[data-color-scheme~="background-tint-dim-strokes-neutral100-background"]': __CACHE__["89"],
    '[data-color-scheme~="background-success-color"]': __CACHE__["90"],
    '[data-color-scheme~="background-success-foregrounds-neutral0"]': __CACHE__["91"],
    '[data-color-scheme~="background-success-foregrounds-neutral"]': __CACHE__["92"],
    '[data-color-scheme~="background-success-foregrounds-neutral100"]': __CACHE__["93"],
    '[data-color-scheme~="background-success-strokes-neutral0"]': __CACHE__["94"],
    '[data-color-scheme~="background-success-strokes-neutral0-background"]': __CACHE__["95"],
    '[data-color-scheme~="background-success-strokes-neutral"]': __CACHE__["96"],
    '[data-color-scheme~="background-success-strokes-neutral-background"]': __CACHE__["97"],
    '[data-color-scheme~="background-success-strokes-neutral100"]': __CACHE__["98"],
    '[data-color-scheme~="background-success-strokes-neutral100-background"]': __CACHE__["99"],
    '[data-color-scheme~="background-success-dim-color"]': __CACHE__["100"],
    '[data-color-scheme~="background-success-dim-foregrounds-neutral0"]': __CACHE__["39"],
    '[data-color-scheme~="background-success-dim-foregrounds-neutral"]': __CACHE__["101"],
    '[data-color-scheme~="background-success-dim-foregrounds-neutral100"]': __CACHE__["93"],
    '[data-color-scheme~="background-success-dim-strokes-neutral0"]': __CACHE__["102"],
    '[data-color-scheme~="background-success-dim-strokes-neutral0-background"]': __CACHE__["103"],
    '[data-color-scheme~="background-success-dim-strokes-neutral"]': __CACHE__["104"],
    '[data-color-scheme~="background-success-dim-strokes-neutral-background"]': __CACHE__["105"],
    '[data-color-scheme~="background-success-dim-strokes-neutral100"]': __CACHE__["106"],
    '[data-color-scheme~="background-success-dim-strokes-neutral100-background"]': __CACHE__["107"],
    '[data-color-scheme~="background-error-color"]': __CACHE__["108"],
    '[data-color-scheme~="background-error-foregrounds-neutral0"]': __CACHE__["109"],
    '[data-color-scheme~="background-error-foregrounds-neutral"]': __CACHE__["109"],
    '[data-color-scheme~="background-error-foregrounds-neutral100"]': __CACHE__["74"],
    '[data-color-scheme~="background-error-strokes-neutral0"]': __CACHE__["110"],
    '[data-color-scheme~="background-error-strokes-neutral0-background"]': __CACHE__["111"],
    '[data-color-scheme~="background-error-strokes-neutral"]': __CACHE__["112"],
    '[data-color-scheme~="background-error-strokes-neutral-background"]': __CACHE__["113"],
    '[data-color-scheme~="background-error-strokes-neutral100"]': __CACHE__["114"],
    '[data-color-scheme~="background-error-strokes-neutral100-background"]': __CACHE__["115"],
    '[data-color-scheme~="background-error-dim-color"]': __CACHE__["116"],
    '[data-color-scheme~="background-error-dim-foregrounds-neutral0"]': __CACHE__["40"],
    '[data-color-scheme~="background-error-dim-foregrounds-neutral"]': __CACHE__["117"],
    '[data-color-scheme~="background-error-dim-foregrounds-neutral100"]': __CACHE__["118"],
    '[data-color-scheme~="background-error-dim-strokes-neutral0"]': __CACHE__["119"],
    '[data-color-scheme~="background-error-dim-strokes-neutral0-background"]': __CACHE__["120"],
    '[data-color-scheme~="background-error-dim-strokes-neutral"]': __CACHE__["121"],
    '[data-color-scheme~="background-error-dim-strokes-neutral-background"]': __CACHE__["122"],
    '[data-color-scheme~="background-error-dim-strokes-neutral100"]': __CACHE__["123"],
    '[data-color-scheme~="background-error-dim-strokes-neutral100-background"]': __CACHE__["124"],
    '[data-color-scheme~="background-info-color"]': __CACHE__["125"],
    '[data-color-scheme~="background-info-foregrounds-neutral0"]': __CACHE__["126"],
    '[data-color-scheme~="background-info-foregrounds-neutral"]': __CACHE__["126"],
    '[data-color-scheme~="background-info-foregrounds-neutral100"]': __CACHE__["74"],
    '[data-color-scheme~="background-info-strokes-neutral0"]': __CACHE__["127"],
    '[data-color-scheme~="background-info-strokes-neutral0-background"]': __CACHE__["128"],
    '[data-color-scheme~="background-info-strokes-neutral"]': __CACHE__["129"],
    '[data-color-scheme~="background-info-strokes-neutral-background"]': __CACHE__["130"],
    '[data-color-scheme~="background-info-strokes-neutral100"]': __CACHE__["131"],
    '[data-color-scheme~="background-info-strokes-neutral100-background"]': __CACHE__["132"],
    '[data-color-scheme~="background-info-dim-color"]': __CACHE__["133"],
    '[data-color-scheme~="background-info-dim-foregrounds-neutral0"]': __CACHE__["41"],
    '[data-color-scheme~="background-info-dim-foregrounds-neutral"]': __CACHE__["134"],
    '[data-color-scheme~="background-info-dim-foregrounds-neutral100"]': __CACHE__["135"],
    '[data-color-scheme~="background-info-dim-strokes-neutral0"]': __CACHE__["136"],
    '[data-color-scheme~="background-info-dim-strokes-neutral0-background"]': __CACHE__["137"],
    '[data-color-scheme~="background-info-dim-strokes-neutral"]': __CACHE__["138"],
    '[data-color-scheme~="background-info-dim-strokes-neutral-background"]': __CACHE__["139"],
    '[data-color-scheme~="background-info-dim-strokes-neutral100"]': __CACHE__["140"],
    '[data-color-scheme~="background-info-dim-strokes-neutral100-background"]': __CACHE__["141"],
    '[data-color-scheme~="background-warning-color"]': __CACHE__["142"],
    '[data-color-scheme~="background-warning-foregrounds-neutral0"]': __CACHE__["143"],
    '[data-color-scheme~="background-warning-foregrounds-neutral"]': __CACHE__["144"],
    '[data-color-scheme~="background-warning-foregrounds-neutral100"]': __CACHE__["145"],
    '[data-color-scheme~="background-warning-strokes-neutral0"]': __CACHE__["146"],
    '[data-color-scheme~="background-warning-strokes-neutral0-background"]': __CACHE__["147"],
    '[data-color-scheme~="background-warning-strokes-neutral"]': __CACHE__["148"],
    '[data-color-scheme~="background-warning-strokes-neutral-background"]': __CACHE__["149"],
    '[data-color-scheme~="background-warning-strokes-neutral100"]': __CACHE__["150"],
    '[data-color-scheme~="background-warning-strokes-neutral100-background"]': __CACHE__["151"],
    '[data-color-scheme~="background-warning-dim-color"]': __CACHE__["152"],
    '[data-color-scheme~="background-warning-dim-foregrounds-neutral0"]': __CACHE__["42"],
    '[data-color-scheme~="background-warning-dim-foregrounds-neutral"]': __CACHE__["153"],
    '[data-color-scheme~="background-warning-dim-foregrounds-neutral100"]': __CACHE__["145"],
    '[data-color-scheme~="background-warning-dim-strokes-neutral0"]': __CACHE__["154"],
    '[data-color-scheme~="background-warning-dim-strokes-neutral0-background"]': __CACHE__["155"],
    '[data-color-scheme~="background-warning-dim-strokes-neutral"]': __CACHE__["156"],
    '[data-color-scheme~="background-warning-dim-strokes-neutral-background"]': __CACHE__["157"],
    '[data-color-scheme~="background-warning-dim-strokes-neutral100"]': __CACHE__["158"],
    '[data-color-scheme~="background-warning-dim-strokes-neutral100-background"]': __CACHE__["159"]
  },
  '.color-scheme-dark':
  {
    '[data-color-scheme~="background-neutral0-color"]': __CACHE__["160"],
    '[data-color-scheme~="background-neutral0-foregrounds-neutral0"]': __CACHE__["161"],
    '[data-color-scheme~="background-neutral0-foregrounds-neutral"]': __CACHE__["162"],
    '[data-color-scheme~="background-neutral0-foregrounds-neutral100"]': __CACHE__["74"],
    '[data-color-scheme~="background-neutral0-foregrounds-tint"]': __CACHE__["163"],
    '[data-color-scheme~="background-neutral0-foregrounds-success"]': __CACHE__["164"],
    '[data-color-scheme~="background-neutral0-foregrounds-error"]': __CACHE__["165"],
    '[data-color-scheme~="background-neutral0-foregrounds-info"]': __CACHE__["166"],
    '[data-color-scheme~="background-neutral0-foregrounds-warning"]': __CACHE__["167"],
    '[data-color-scheme~="background-neutral0-strokes-neutral0"]': __CACHE__["168"],
    '[data-color-scheme~="background-neutral0-strokes-neutral0-background"]': __CACHE__["169"],
    '[data-color-scheme~="background-neutral0-strokes-neutral"]': __CACHE__["170"],
    '[data-color-scheme~="background-neutral0-strokes-neutral-background"]': __CACHE__["171"],
    '[data-color-scheme~="background-neutral0-strokes-neutral100"]': __CACHE__["172"],
    '[data-color-scheme~="background-neutral0-strokes-neutral100-background"]': __CACHE__["173"],
    '[data-color-scheme~="background-neutral0-strokes-tint"]': __CACHE__["174"],
    '[data-color-scheme~="background-neutral0-strokes-tint-background"]': __CACHE__["175"],
    '[data-color-scheme~="background-neutral0-strokes-tint100"]': __CACHE__["176"],
    '[data-color-scheme~="background-neutral0-strokes-tint100-background"]': __CACHE__["177"],
    '[data-color-scheme~="background-neutral0-strokes-success"]': __CACHE__["178"],
    '[data-color-scheme~="background-neutral0-strokes-success-background"]': __CACHE__["179"],
    '[data-color-scheme~="background-neutral0-strokes-success100"]': __CACHE__["180"],
    '[data-color-scheme~="background-neutral0-strokes-success100-background"]': __CACHE__["181"],
    '[data-color-scheme~="background-neutral0-strokes-error"]': __CACHE__["182"],
    '[data-color-scheme~="background-neutral0-strokes-error-background"]': __CACHE__["183"],
    '[data-color-scheme~="background-neutral0-strokes-error100"]': __CACHE__["184"],
    '[data-color-scheme~="background-neutral0-strokes-error100-background"]': __CACHE__["185"],
    '[data-color-scheme~="background-neutral0-strokes-info"]': __CACHE__["186"],
    '[data-color-scheme~="background-neutral0-strokes-info-background"]': __CACHE__["187"],
    '[data-color-scheme~="background-neutral0-strokes-info100"]': __CACHE__["188"],
    '[data-color-scheme~="background-neutral0-strokes-info100-background"]': __CACHE__["189"],
    '[data-color-scheme~="background-neutral0-strokes-warning"]': __CACHE__["190"],
    '[data-color-scheme~="background-neutral0-strokes-warning-background"]': __CACHE__["191"],
    '[data-color-scheme~="background-neutral0-strokes-warning100"]': __CACHE__["192"],
    '[data-color-scheme~="background-neutral0-strokes-warning100-background"]': __CACHE__["193"],
    '[data-color-scheme~="background-neutral25-color"]': __CACHE__["194"],
    '[data-color-scheme~="background-neutral25-foregrounds-neutral0"]': __CACHE__["161"],
    '[data-color-scheme~="background-neutral25-foregrounds-neutral"]': __CACHE__["162"],
    '[data-color-scheme~="background-neutral25-foregrounds-neutral100"]': __CACHE__["74"],
    '[data-color-scheme~="background-neutral25-foregrounds-tint"]': __CACHE__["163"],
    '[data-color-scheme~="background-neutral25-foregrounds-success"]': __CACHE__["164"],
    '[data-color-scheme~="background-neutral25-foregrounds-error"]': __CACHE__["165"],
    '[data-color-scheme~="background-neutral25-foregrounds-info"]': __CACHE__["166"],
    '[data-color-scheme~="background-neutral25-foregrounds-warning"]': __CACHE__["167"],
    '[data-color-scheme~="background-neutral25-strokes-neutral0"]': __CACHE__["168"],
    '[data-color-scheme~="background-neutral25-strokes-neutral0-background"]': __CACHE__["169"],
    '[data-color-scheme~="background-neutral25-strokes-neutral"]': __CACHE__["170"],
    '[data-color-scheme~="background-neutral25-strokes-neutral-background"]': __CACHE__["171"],
    '[data-color-scheme~="background-neutral25-strokes-neutral100"]': __CACHE__["172"],
    '[data-color-scheme~="background-neutral25-strokes-neutral100-background"]': __CACHE__["173"],
    '[data-color-scheme~="background-neutral25-strokes-tint"]': __CACHE__["174"],
    '[data-color-scheme~="background-neutral25-strokes-tint-background"]': __CACHE__["175"],
    '[data-color-scheme~="background-neutral25-strokes-tint100"]': __CACHE__["176"],
    '[data-color-scheme~="background-neutral25-strokes-tint100-background"]': __CACHE__["177"],
    '[data-color-scheme~="background-neutral25-strokes-success"]': __CACHE__["178"],
    '[data-color-scheme~="background-neutral25-strokes-success-background"]': __CACHE__["179"],
    '[data-color-scheme~="background-neutral25-strokes-success100"]': __CACHE__["180"],
    '[data-color-scheme~="background-neutral25-strokes-success100-background"]': __CACHE__["181"],
    '[data-color-scheme~="background-neutral25-strokes-error"]': __CACHE__["182"],
    '[data-color-scheme~="background-neutral25-strokes-error-background"]': __CACHE__["183"],
    '[data-color-scheme~="background-neutral25-strokes-error100"]': __CACHE__["184"],
    '[data-color-scheme~="background-neutral25-strokes-error100-background"]': __CACHE__["185"],
    '[data-color-scheme~="background-neutral25-strokes-info"]': __CACHE__["186"],
    '[data-color-scheme~="background-neutral25-strokes-info-background"]': __CACHE__["187"],
    '[data-color-scheme~="background-neutral25-strokes-info100"]': __CACHE__["188"],
    '[data-color-scheme~="background-neutral25-strokes-info100-background"]': __CACHE__["189"],
    '[data-color-scheme~="background-neutral25-strokes-warning"]': __CACHE__["190"],
    '[data-color-scheme~="background-neutral25-strokes-warning-background"]': __CACHE__["191"],
    '[data-color-scheme~="background-neutral25-strokes-warning100"]': __CACHE__["192"],
    '[data-color-scheme~="background-neutral25-strokes-warning100-background"]': __CACHE__["193"],
    '[data-color-scheme~="background-neutral-color"]': __CACHE__["195"],
    '[data-color-scheme~="background-neutral-foregrounds-neutral0"]': __CACHE__["161"],
    '[data-color-scheme~="background-neutral-foregrounds-neutral"]': __CACHE__["162"],
    '[data-color-scheme~="background-neutral-foregrounds-neutral100"]': __CACHE__["74"],
    '[data-color-scheme~="background-neutral-foregrounds-tint"]': __CACHE__["163"],
    '[data-color-scheme~="background-neutral-foregrounds-success"]': __CACHE__["164"],
    '[data-color-scheme~="background-neutral-foregrounds-error"]': __CACHE__["165"],
    '[data-color-scheme~="background-neutral-foregrounds-info"]': __CACHE__["166"],
    '[data-color-scheme~="background-neutral-foregrounds-warning"]': __CACHE__["167"],
    '[data-color-scheme~="background-neutral-strokes-neutral0"]': __CACHE__["168"],
    '[data-color-scheme~="background-neutral-strokes-neutral0-background"]': __CACHE__["169"],
    '[data-color-scheme~="background-neutral-strokes-neutral"]': __CACHE__["170"],
    '[data-color-scheme~="background-neutral-strokes-neutral-background"]': __CACHE__["171"],
    '[data-color-scheme~="background-neutral-strokes-neutral100"]': __CACHE__["172"],
    '[data-color-scheme~="background-neutral-strokes-neutral100-background"]': __CACHE__["173"],
    '[data-color-scheme~="background-neutral-strokes-tint"]': __CACHE__["174"],
    '[data-color-scheme~="background-neutral-strokes-tint-background"]': __CACHE__["175"],
    '[data-color-scheme~="background-neutral-strokes-tint100"]': __CACHE__["176"],
    '[data-color-scheme~="background-neutral-strokes-tint100-background"]': __CACHE__["177"],
    '[data-color-scheme~="background-neutral-strokes-success"]': __CACHE__["178"],
    '[data-color-scheme~="background-neutral-strokes-success-background"]': __CACHE__["179"],
    '[data-color-scheme~="background-neutral-strokes-success100"]': __CACHE__["180"],
    '[data-color-scheme~="background-neutral-strokes-success100-background"]': __CACHE__["181"],
    '[data-color-scheme~="background-neutral-strokes-error"]': __CACHE__["182"],
    '[data-color-scheme~="background-neutral-strokes-error-background"]': __CACHE__["183"],
    '[data-color-scheme~="background-neutral-strokes-error100"]': __CACHE__["184"],
    '[data-color-scheme~="background-neutral-strokes-error100-background"]': __CACHE__["185"],
    '[data-color-scheme~="background-neutral-strokes-info"]': __CACHE__["186"],
    '[data-color-scheme~="background-neutral-strokes-info-background"]': __CACHE__["187"],
    '[data-color-scheme~="background-neutral-strokes-info100"]': __CACHE__["188"],
    '[data-color-scheme~="background-neutral-strokes-info100-background"]': __CACHE__["189"],
    '[data-color-scheme~="background-neutral-strokes-warning"]': __CACHE__["190"],
    '[data-color-scheme~="background-neutral-strokes-warning-background"]': __CACHE__["191"],
    '[data-color-scheme~="background-neutral-strokes-warning100"]': __CACHE__["192"],
    '[data-color-scheme~="background-neutral-strokes-warning100-background"]': __CACHE__["193"],
    '[data-color-scheme~="background-neutral75-color"]': __CACHE__["196"],
    '[data-color-scheme~="background-neutral75-foregrounds-neutral0"]': __CACHE__["161"],
    '[data-color-scheme~="background-neutral75-foregrounds-neutral"]': __CACHE__["162"],
    '[data-color-scheme~="background-neutral75-foregrounds-neutral100"]': __CACHE__["74"],
    '[data-color-scheme~="background-neutral75-foregrounds-tint"]': __CACHE__["163"],
    '[data-color-scheme~="background-neutral75-foregrounds-success"]': __CACHE__["164"],
    '[data-color-scheme~="background-neutral75-foregrounds-error"]': __CACHE__["165"],
    '[data-color-scheme~="background-neutral75-foregrounds-info"]': __CACHE__["166"],
    '[data-color-scheme~="background-neutral75-foregrounds-warning"]': __CACHE__["167"],
    '[data-color-scheme~="background-neutral75-strokes-neutral0"]': __CACHE__["168"],
    '[data-color-scheme~="background-neutral75-strokes-neutral0-background"]': __CACHE__["169"],
    '[data-color-scheme~="background-neutral75-strokes-neutral"]': __CACHE__["170"],
    '[data-color-scheme~="background-neutral75-strokes-neutral-background"]': __CACHE__["171"],
    '[data-color-scheme~="background-neutral75-strokes-neutral100"]': __CACHE__["172"],
    '[data-color-scheme~="background-neutral75-strokes-neutral100-background"]': __CACHE__["173"],
    '[data-color-scheme~="background-neutral75-strokes-tint"]': __CACHE__["174"],
    '[data-color-scheme~="background-neutral75-strokes-tint-background"]': __CACHE__["175"],
    '[data-color-scheme~="background-neutral75-strokes-tint100"]': __CACHE__["176"],
    '[data-color-scheme~="background-neutral75-strokes-tint100-background"]': __CACHE__["177"],
    '[data-color-scheme~="background-neutral75-strokes-success"]': __CACHE__["178"],
    '[data-color-scheme~="background-neutral75-strokes-success-background"]': __CACHE__["179"],
    '[data-color-scheme~="background-neutral75-strokes-success100"]': __CACHE__["180"],
    '[data-color-scheme~="background-neutral75-strokes-success100-background"]': __CACHE__["181"],
    '[data-color-scheme~="background-neutral75-strokes-error"]': __CACHE__["182"],
    '[data-color-scheme~="background-neutral75-strokes-error-background"]': __CACHE__["183"],
    '[data-color-scheme~="background-neutral75-strokes-error100"]': __CACHE__["184"],
    '[data-color-scheme~="background-neutral75-strokes-error100-background"]': __CACHE__["185"],
    '[data-color-scheme~="background-neutral75-strokes-info"]': __CACHE__["186"],
    '[data-color-scheme~="background-neutral75-strokes-info-background"]': __CACHE__["187"],
    '[data-color-scheme~="background-neutral75-strokes-info100"]': __CACHE__["188"],
    '[data-color-scheme~="background-neutral75-strokes-info100-background"]': __CACHE__["189"],
    '[data-color-scheme~="background-neutral75-strokes-warning"]': __CACHE__["190"],
    '[data-color-scheme~="background-neutral75-strokes-warning-background"]': __CACHE__["191"],
    '[data-color-scheme~="background-neutral75-strokes-warning100"]': __CACHE__["192"],
    '[data-color-scheme~="background-neutral75-strokes-warning100-background"]': __CACHE__["193"],
    '[data-color-scheme~="background-tint-color"]': __CACHE__["72"],
    '[data-color-scheme~="background-tint-foregrounds-neutral0"]': __CACHE__["73"],
    '[data-color-scheme~="background-tint-foregrounds-neutral"]': __CACHE__["73"],
    '[data-color-scheme~="background-tint-foregrounds-neutral100"]': __CACHE__["74"],
    '[data-color-scheme~="background-tint-strokes-neutral0"]': __CACHE__["75"],
    '[data-color-scheme~="background-tint-strokes-neutral0-background"]': __CACHE__["76"],
    '[data-color-scheme~="background-tint-strokes-neutral"]': __CACHE__["77"],
    '[data-color-scheme~="background-tint-strokes-neutral-background"]': __CACHE__["78"],
    '[data-color-scheme~="background-tint-strokes-neutral100"]': __CACHE__["79"],
    '[data-color-scheme~="background-tint-strokes-neutral100-background"]': __CACHE__["80"],
    '[data-color-scheme~="background-tint-dim-color"]': __CACHE__["197"],
    '[data-color-scheme~="background-tint-dim-foregrounds-neutral0"]': __CACHE__["163"],
    '[data-color-scheme~="background-tint-dim-foregrounds-neutral"]': __CACHE__["198"],
    '[data-color-scheme~="background-tint-dim-foregrounds-neutral100"]': __CACHE__["74"],
    '[data-color-scheme~="background-tint-dim-strokes-neutral0"]': __CACHE__["199"],
    '[data-color-scheme~="background-tint-dim-strokes-neutral0-background"]': __CACHE__["200"],
    '[data-color-scheme~="background-tint-dim-strokes-neutral"]': __CACHE__["201"],
    '[data-color-scheme~="background-tint-dim-strokes-neutral-background"]': __CACHE__["81"],
    '[data-color-scheme~="background-tint-dim-strokes-neutral100"]': __CACHE__["202"],
    '[data-color-scheme~="background-tint-dim-strokes-neutral100-background"]': __CACHE__["203"],
    '[data-color-scheme~="background-success-color"]': __CACHE__["90"],
    '[data-color-scheme~="background-success-foregrounds-neutral0"]': __CACHE__["91"],
    '[data-color-scheme~="background-success-foregrounds-neutral"]': __CACHE__["92"],
    '[data-color-scheme~="background-success-foregrounds-neutral100"]': __CACHE__["93"],
    '[data-color-scheme~="background-success-strokes-neutral0"]': __CACHE__["94"],
    '[data-color-scheme~="background-success-strokes-neutral0-background"]': __CACHE__["95"],
    '[data-color-scheme~="background-success-strokes-neutral"]': __CACHE__["96"],
    '[data-color-scheme~="background-success-strokes-neutral-background"]': __CACHE__["97"],
    '[data-color-scheme~="background-success-strokes-neutral100"]': __CACHE__["98"],
    '[data-color-scheme~="background-success-strokes-neutral100-background"]': __CACHE__["99"],
    '[data-color-scheme~="background-success-dim-color"]': __CACHE__["204"],
    '[data-color-scheme~="background-success-dim-foregrounds-neutral0"]': __CACHE__["164"],
    '[data-color-scheme~="background-success-dim-foregrounds-neutral"]': __CACHE__["205"],
    '[data-color-scheme~="background-success-dim-foregrounds-neutral100"]': __CACHE__["74"],
    '[data-color-scheme~="background-success-dim-strokes-neutral0"]': __CACHE__["206"],
    '[data-color-scheme~="background-success-dim-strokes-neutral0-background"]': __CACHE__["207"],
    '[data-color-scheme~="background-success-dim-strokes-neutral"]': __CACHE__["208"],
    '[data-color-scheme~="background-success-dim-strokes-neutral-background"]': __CACHE__["100"],
    '[data-color-scheme~="background-success-dim-strokes-neutral100"]': __CACHE__["209"],
    '[data-color-scheme~="background-success-dim-strokes-neutral100-background"]': __CACHE__["210"],
    '[data-color-scheme~="background-error-color"]': __CACHE__["108"],
    '[data-color-scheme~="background-error-foregrounds-neutral0"]': __CACHE__["109"],
    '[data-color-scheme~="background-error-foregrounds-neutral"]': __CACHE__["109"],
    '[data-color-scheme~="background-error-foregrounds-neutral100"]': __CACHE__["74"],
    '[data-color-scheme~="background-error-strokes-neutral0"]': __CACHE__["110"],
    '[data-color-scheme~="background-error-strokes-neutral0-background"]': __CACHE__["111"],
    '[data-color-scheme~="background-error-strokes-neutral"]': __CACHE__["112"],
    '[data-color-scheme~="background-error-strokes-neutral-background"]': __CACHE__["113"],
    '[data-color-scheme~="background-error-strokes-neutral100"]': __CACHE__["114"],
    '[data-color-scheme~="background-error-strokes-neutral100-background"]': __CACHE__["115"],
    '[data-color-scheme~="background-error-dim-color"]': __CACHE__["211"],
    '[data-color-scheme~="background-error-dim-foregrounds-neutral0"]': __CACHE__["165"],
    '[data-color-scheme~="background-error-dim-foregrounds-neutral"]': __CACHE__["212"],
    '[data-color-scheme~="background-error-dim-foregrounds-neutral100"]': __CACHE__["74"],
    '[data-color-scheme~="background-error-dim-strokes-neutral0"]': __CACHE__["213"],
    '[data-color-scheme~="background-error-dim-strokes-neutral0-background"]': __CACHE__["214"],
    '[data-color-scheme~="background-error-dim-strokes-neutral"]': __CACHE__["215"],
    '[data-color-scheme~="background-error-dim-strokes-neutral-background"]': __CACHE__["116"],
    '[data-color-scheme~="background-error-dim-strokes-neutral100"]': __CACHE__["216"],
    '[data-color-scheme~="background-error-dim-strokes-neutral100-background"]': __CACHE__["217"],
    '[data-color-scheme~="background-info-color"]': __CACHE__["125"],
    '[data-color-scheme~="background-info-foregrounds-neutral0"]': __CACHE__["126"],
    '[data-color-scheme~="background-info-foregrounds-neutral"]': __CACHE__["126"],
    '[data-color-scheme~="background-info-foregrounds-neutral100"]': __CACHE__["74"],
    '[data-color-scheme~="background-info-strokes-neutral0"]': __CACHE__["127"],
    '[data-color-scheme~="background-info-strokes-neutral0-background"]': __CACHE__["128"],
    '[data-color-scheme~="background-info-strokes-neutral"]': __CACHE__["129"],
    '[data-color-scheme~="background-info-strokes-neutral-background"]': __CACHE__["130"],
    '[data-color-scheme~="background-info-strokes-neutral100"]': __CACHE__["131"],
    '[data-color-scheme~="background-info-strokes-neutral100-background"]': __CACHE__["132"],
    '[data-color-scheme~="background-info-dim-color"]': __CACHE__["218"],
    '[data-color-scheme~="background-info-dim-foregrounds-neutral0"]': __CACHE__["166"],
    '[data-color-scheme~="background-info-dim-foregrounds-neutral"]': __CACHE__["219"],
    '[data-color-scheme~="background-info-dim-foregrounds-neutral100"]': __CACHE__["74"],
    '[data-color-scheme~="background-info-dim-strokes-neutral0"]': __CACHE__["220"],
    '[data-color-scheme~="background-info-dim-strokes-neutral0-background"]': __CACHE__["221"],
    '[data-color-scheme~="background-info-dim-strokes-neutral"]': __CACHE__["222"],
    '[data-color-scheme~="background-info-dim-strokes-neutral-background"]': __CACHE__["133"],
    '[data-color-scheme~="background-info-dim-strokes-neutral100"]': __CACHE__["223"],
    '[data-color-scheme~="background-info-dim-strokes-neutral100-background"]': __CACHE__["224"],
    '[data-color-scheme~="background-warning-color"]': __CACHE__["142"],
    '[data-color-scheme~="background-warning-foregrounds-neutral0"]': __CACHE__["143"],
    '[data-color-scheme~="background-warning-foregrounds-neutral"]': __CACHE__["144"],
    '[data-color-scheme~="background-warning-foregrounds-neutral100"]': __CACHE__["145"],
    '[data-color-scheme~="background-warning-strokes-neutral0"]': __CACHE__["146"],
    '[data-color-scheme~="background-warning-strokes-neutral0-background"]': __CACHE__["147"],
    '[data-color-scheme~="background-warning-strokes-neutral"]': __CACHE__["148"],
    '[data-color-scheme~="background-warning-strokes-neutral-background"]': __CACHE__["149"],
    '[data-color-scheme~="background-warning-strokes-neutral100"]': __CACHE__["150"],
    '[data-color-scheme~="background-warning-strokes-neutral100-background"]': __CACHE__["151"],
    '[data-color-scheme~="background-warning-dim-color"]': __CACHE__["225"],
    '[data-color-scheme~="background-warning-dim-foregrounds-neutral0"]': __CACHE__["167"],
    '[data-color-scheme~="background-warning-dim-foregrounds-neutral"]': __CACHE__["226"],
    '[data-color-scheme~="background-warning-dim-foregrounds-neutral100"]': __CACHE__["74"],
    '[data-color-scheme~="background-warning-dim-strokes-neutral0"]': __CACHE__["227"],
    '[data-color-scheme~="background-warning-dim-strokes-neutral0-background"]': __CACHE__["228"],
    '[data-color-scheme~="background-warning-dim-strokes-neutral"]': __CACHE__["229"],
    '[data-color-scheme~="background-warning-dim-strokes-neutral-background"]': __CACHE__["152"],
    '[data-color-scheme~="background-warning-dim-strokes-neutral100"]': __CACHE__["230"],
    '[data-color-scheme~="background-warning-dim-strokes-neutral100-background"]': __CACHE__["231"]
  }
} as const;

export const defaultBackground = 'neutral' as const;

export const defaultTypeScheme = 'm' as const;

export const defaultTextPreset = '50' as const;

export const typeSchemeStyles = {
  s:
  {
    '30': __CACHE__["232"],
    '40': __CACHE__["233"],
    '50': __CACHE__["234"],
    '60': __CACHE__["235"],
    '70': __CACHE__["236"],
    h90: __CACHE__["237"],
    h80: __CACHE__["238"],
    h70: __CACHE__["239"],
    h60: __CACHE__["240"],
    h50: __CACHE__["241"],
    h40: __CACHE__["242"],
    b70: __CACHE__["243"],
    b60: __CACHE__["244"],
    b50: __CACHE__["245"],
    b40: __CACHE__["246"],
    b30: __CACHE__["247"],
    o60: __CACHE__["248"],
    o50: __CACHE__["249"],
    o40: __CACHE__["250"],
    c50: __CACHE__["251"]
  },
  m:
  {
    '30': __CACHE__["233"],
    '40': __CACHE__["234"],
    '50': __CACHE__["235"],
    '60': __CACHE__["236"],
    '70': __CACHE__["252"],
    h90: __CACHE__["253"],
    h80: __CACHE__["237"],
    h70: __CACHE__["238"],
    h60: __CACHE__["239"],
    h50: __CACHE__["240"],
    h40: __CACHE__["241"],
    b70: __CACHE__["254"],
    b60: __CACHE__["243"],
    b50: __CACHE__["244"],
    b40: __CACHE__["245"],
    b30: __CACHE__["246"],
    o60: __CACHE__["255"],
    o50: __CACHE__["248"],
    o40: __CACHE__["249"],
    c50: __CACHE__["256"]
  },
  l:
  {
    '30': __CACHE__["234"],
    '40': __CACHE__["235"],
    '50': __CACHE__["236"],
    '60': __CACHE__["252"],
    '70': __CACHE__["257"],
    h90: __CACHE__["258"],
    h80: __CACHE__["253"],
    h70: __CACHE__["237"],
    h60: __CACHE__["238"],
    h50: __CACHE__["239"],
    h40: __CACHE__["240"],
    b70: __CACHE__["259"],
    b60: __CACHE__["254"],
    b50: __CACHE__["243"],
    b40: __CACHE__["244"],
    b30: __CACHE__["245"],
    o60: __CACHE__["255"],
    o50: __CACHE__["248"],
    o40: __CACHE__["249"],
    c50: __CACHE__["256"]
  },
  'doc-m':
  {
    '30': __CACHE__["233"],
    '40': __CACHE__["234"],
    '50': __CACHE__["235"],
    '60': __CACHE__["252"],
    '70': __CACHE__["257"],
    h90: __CACHE__["253"],
    h80: __CACHE__["260"],
    h70: __CACHE__["237"],
    h60: __CACHE__["238"],
    h50: __CACHE__["240"],
    h40: __CACHE__["241"],
    b70: __CACHE__["259"],
    b60: __CACHE__["254"],
    b50: __CACHE__["244"],
    b40: __CACHE__["245"],
    b30: __CACHE__["246"],
    o60: __CACHE__["255"],
    o50: __CACHE__["248"],
    o40: __CACHE__["249"],
    c50: __CACHE__["256"]
  },
  'doc-l':
  {
    '30': __CACHE__["234"],
    '40': __CACHE__["235"],
    '50': __CACHE__["236"],
    '60': __CACHE__["252"],
    '70': __CACHE__["257"],
    h90: __CACHE__["261"],
    h80: __CACHE__["253"],
    h70: __CACHE__["237"],
    h60: __CACHE__["238"],
    h50: __CACHE__["239"],
    h40: __CACHE__["240"],
    b70: __CACHE__["259"],
    b60: __CACHE__["254"],
    b50: __CACHE__["243"],
    b40: __CACHE__["244"],
    b30: __CACHE__["245"],
    o60: __CACHE__["255"],
    o50: __CACHE__["248"],
    o40: __CACHE__["249"],
    c50: __CACHE__["256"]
  }
} as const;

export const fontWeightStyles = {
  System:
  {
    normal: __CACHE__["262"],
    bold: __CACHE__["263"]
  },
  'SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace':
  {
    normal: __CACHE__["262"],
    bold: __CACHE__["263"]
  }
} as const;

export const responsiveTypeMap = { doc: __CACHE__["264"] } as const;

export const components = {
  Text: __CACHE__["265"],
  TextInput: __CACHE__["266"],
  InputField:
  {
    sizes: __CACHE__["267"],
    defaultSize: '50',
    defaultBackground: 'neutral0',
    defaultBorderRadius: '60',
    defaultBorderWidth: '50',
    defaultBorderColor: 'neutral0'
  },
  ActivityIndicator:
  {
    sizes: __CACHE__["268"],
    defaultSize: '50',
    defaultColor: 'neutral'
  },
  BottomTabBar: __CACHE__["269"],
  HeaderNav: __CACHE__["270"]
} as const;

export const defaultContentSize = 'm' as const;

export const boxSizeRatios = {
  s: __CACHE__["271"],
  m: __CACHE__["272"],
  l: __CACHE__["273"]
} as const;

export const space = {
  '10': 2,
  '20': 4,
  '30': 8,
  '40': 12,
  '50': 16,
  '60': 24,
  '70': 32,
  '80': 40,
  '90': 48,
  l20: 16,
  l30: 24,
  l40: 32,
  l50: 48,
  l60: 64,
  l70: 96,
  l80: 160
} as const;

export const radii = { '40': 2, '50': 4, '60': 8, '70': 16, full: 999 } as const;

export const shadows = {
  '50': __CACHE__["274"],
  '60': __CACHE__["275"]
} as const;

export const zIndices = { top: 999 } as const;

