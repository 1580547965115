import { DefaultTheme } from '@lib/ui';

const Theme = {
  ...DefaultTheme,
} as const;

export default Theme;

/**
 * Set up global theme type for IntelliSense.
 */
type LocalThemeSpec = typeof Theme;

declare global {
  interface ThemeSpec extends LocalThemeSpec {}
}
