import React from 'react';
import { COLOR_SCHEME_KEY, WebColorSchemeInit, UIProvider } from '../../theming';
import { ThemeShape } from '../../types';

export function gatsbySSR(
  theme: ThemeShape,
  {
    headComponents = [],
    preBodyComponents = [],
  }: {
    headComponents?: React.ReactNode[];
    preBodyComponents?: React.ReactNode[];
  } = {}
) {
  return {
    onRenderBody: ({
      setBodyAttributes,
      setHeadComponents,
      setPreBodyComponents,
    }: {
      setBodyAttributes: (attrs: Record<string, string>) => void;
      setHeadComponents: (elements: React.ReactNode[]) => void;
      setPreBodyComponents: (elements: React.ReactNode[]) => void;
    }) => {
      setHeadComponents(headComponents);
      // Set default scheme class so there are colors even when there's no JS
      setBodyAttributes({ className: `${COLOR_SCHEME_KEY}-${theme.defaultColorScheme}` });
      setPreBodyComponents([
        <WebColorSchemeInit defaultScheme={theme.defaultColorScheme} />,
        ...preBodyComponents,
      ]);
    },
    wrapRootElement: ({ element }: { element: React.ReactNode }) => (
      <UIProvider theme={theme}>{element}</UIProvider>
    ),
  };
}
