import { createContext, useContext } from 'react';
import { ThemeColorScheme } from '../types';

export const ColorSchemeContext = createContext<{
  colorScheme: ThemeColorScheme;
  loading: boolean;
  setColorScheme: (appScheme: ThemeColorScheme | null) => void;
}>({ colorScheme: 'light', loading: true, setColorScheme: () => {} });

export function useColorScheme() {
  return useContext(ColorSchemeContext);
}
