import React, { useMemo, cloneElement } from 'react';
import { Platform } from 'react-native';
import styleResolver from 'react-native-web/dist/exports/StyleSheet/styleResolver';
import { CacheProvider, Global, ClassNames } from '@emotion/core';
import createCache from '@emotion/cache';
import { mapValues } from 'lodash';

/**
 * Patch `emotion` to data attributes instead of classes.
 */

export function CSSProvider({
  globalCSS,
  children,
}: {
  globalCSS?: object;
  children: React.ReactNode;
}) {
  const emotionCache = useMemo(
    () =>
      createCache({
        stylisPlugins: [classNameToDataAttributePlugin],
      }),
    []
  );

  return (
    <CacheProvider value={emotionCache}>
      {globalCSS ? <Global styles={globalCSS as any} /> : null}
      {children}
    </CacheProvider>
  );
}

export function WebStyle({
  children,
  style,
}: {
  children: React.ReactElement;
  // style: NativeStyle | IWebStyle;
  style: any;
}) {
  if (Platform.OS !== 'web') {
    return children;
  }

  return (
    <ClassNames>
      {({ css }) => {
        return cloneElement(
          children,
          classNameToDataAttribute(
            css(
              resolveStyle(
                mapValues(style, (value, key) => (isCSSObject(key) ? resolveStyle(value) : value))
              )
            )
          )
        );
      }}
    </ClassNames>
  );
}

// Helpers

function classNameToDataAttributePlugin(context: number, content: string) {
  switch (context) {
    case -2:
      // Match `.css-*` until a non-word character (e.g. `:` or `{`)
      // https://stackoverflow.com/questions/7124778/how-to-match-anything-up-until-this-sequence-of-characters-in-a-regular-expres
      return content.replace(/\.css-.+?(?=\W)/gi, match => {
        return `[data-${match.substring(1)}]`;
      });
  }
}

function classNameToDataAttribute(className: string) {
  const dataAttr = className.replace(/css-.+?(?=(\W|$))/gi, match => {
    return `data-${match}`;
  });

  return { [dataAttr]: true };
}

const cssObjectKeys = ['@media', ':hover', ':active', ':focus', '&'];
const cssObjectRegexp = new RegExp(`^(${cssObjectKeys.join('|')})`);
const isCSSObject = (key: string) => cssObjectRegexp.test(key);

function resolveStyle(style: object) {
  return styleResolver.resolve(style).style;
}
