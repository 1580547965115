import { StyleSheet } from 'react-native';

export * from './__generated__/static';

export const borderWidths = {
  '40': StyleSheet.hairlineWidth,
  '50': 1,
  '60': 2,
  '70': 4,
};
